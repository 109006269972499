import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import SubjectTwoToneIcon from '@mui/icons-material/SubjectTwoTone';
import { image } from 'd3';

export const sidebarMenuAdmin = [
  {
    caption: 'ASSESSMENT MODULE',
    subMenu: [
      {
        permission: 'get:assessment',
        caption: 'Manage Assessments',
        navigate: '/assessment/list',
        image: '/static/images/sidebar/Assessment.svg'
      },
      {
        permission: 'get:question',
        caption: 'Manage Questions ',
        navigate: '/question',
        image: '/static/images/sidebar/Question_Pool.svg'
      }
    ]
  },
  {
    caption: 'Organization ',
    subMenu: [
      {
        permission: 'get:business',
        caption: 'Manage Organizations ',
        navigate: '/business',
        image: '/static/images/sidebar/Business.svg'
      }
    ]
  },
  {
    caption: 'USERS',
    subMenu: [
      {
        permission: 'get:teacher',
        caption: 'Manage Teachers',
        navigate: '/teacher',
        image: '/static/images/sidebar/Teachers.svg'
      },
      {
        permission: 'get:sub-admin',
        caption: 'Administration',
        navigate: '/admin',
        image: '/static/images/sidebar/Admin.svg'
      }
    ]
  },
  {
    caption: 'Tag Management',
    subMenu: [
      {
        permission: 'get:grade',
        caption: 'Grades',
        navigate: '/grade',
        image: '/static/images/sidebar/Grade.svg'
      },
      {
        permission: 'get:subject',
        caption: 'Subjects',
        navigate: '/subject',
        image: '/static/images/sidebar/Subject.svg'
      },
      {
        permission: 'get:category',
        caption: 'Competencies',
        navigate: '/generalCategory',
        image: '/static/images/sidebar/General_Category.svg'
      }
    ]
  },
  {
    caption: 'REPORT',
    subMenu: [
      {
        permission: 'get:report',
        caption: 'Reports',
        navigate: '/report',
        image: '/static/images/sidebar/Report.svg'
      }
    ]
  },
  {
    caption: 'SETTINGS',
    subMenu: [
      {
        permission: 'get:roles',
        caption: 'Manage Roles and Privileges',
        navigate: '/role',
        image: '/static/images/sidebar/Add_user.svg'
      },
      {
        permission: '',
        caption: 'Profile',
        navigate: '/profile',
        image: '/static/images/sidebar/Admin.svg'
      }
    ]
  }
];

export const sidebarMenuBusiness = [
  {
    caption: 'ASSESSMENT MODULE',
    subMenu: [
      {
        permission: 'get:assessment',
        caption: 'Manage Assessments',
        navigate: '/assessment/list',
        image: 'static/images/sidebar/Assessment.svg'
      }
    ]
  },
  {
    caption: 'USERS',
    subMenu: [
      {
        permission: 'get:teacher',
        caption: 'Manage Teachers',
        navigate: '/teacher',
        image: '/static/images/sidebar/Teachers.svg'
      },
      {
        permission: 'get:sub-admin-business',
        caption: 'Administration',
        navigate: '/admin-business',
        image: '/static/images/sidebar/Admin.svg'
      }
    ]
  },
  {
    caption: 'REPORT',
    subMenu: [
      {
        permission: 'get:report',
        caption: 'Report',
        navigate: '/report',
        image: '/static/images/sidebar/Report.svg'
      }
    ]
  },
  {
    caption: 'SETTINGS',
    subMenu: [
      {
        permission: 'get:roles',
        caption: 'Manage Roles and Privileges',
        navigate: '/role',
        image: '/static/images/sidebar/Add_user.svg'
      },
      {
        permission: '',
        caption: 'Profile',
        navigate: '/profile',
        image: '/static/images/sidebar/Admin.svg'
      }
    ]
  }
];

export const sidebarMenuSubBusiness = [
  {
    caption: 'ASSESSMENT MODULE',
    subMenu: [
      {
        permission: 'get:assessment',
        caption: 'Manage Assessments',
        navigate: '/assessment/list',
        image: 'static/images/sidebar/Assessment.svg'
      }
    ]
  },
  {
    caption: 'USERS',
    subMenu: [
      {
        permission: 'get:teacher',
        caption: 'Manage Teachers',
        navigate: '/teacher',
        image: '/static/images/sidebar/Teachers.svg'
      },
      {
        permission: 'get:sub-admin',
        caption: 'Administration',
        navigate: '/admin',
        image: '/static/images/sidebar/Admin.svg'
      }
    ]
  },
  {
    caption: 'REPORT',
    subMenu: [
      {
        permission: 'get:report',
        caption: 'Report',
        navigate: '/report',
        image: '/static/images/sidebar/Report.svg'
      }
    ]
  },
  {
    caption: 'SETTINGS',
    subMenu: [
      {
        permission: '',
        caption: 'Profile',
        navigate: '/profile',
        image: '/static/images/sidebar/Admin.svg'
      }
    ]
  }
];

export const sidebarMenuTeacher = [
  {
    caption: 'ASSESSMENT MODULE',
    subMenu: [
      {
        permission: '',
        caption: 'Assessment',
        navigate: '/teacherAssessment',
        image: 'static/images/sidebar/Assessment.svg'
      }
    ]
  },
  {
    caption: 'SETTINGS',
    subMenu: [
      {
        permission: '',
        caption: 'Profile',
        navigate: '/profile',
        image: '/static/images/sidebar/Admin.svg'
      }
    ]
  }
];
