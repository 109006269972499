import { Button, Grid } from '@mui/material';

const CustomButton = ({ onClick, title, sx = {}, type }) => {

  const defaultStyles = {
    backgroundColor: type != 'cancel' ? "#380563" : 'rgba(56, 5, 99, 0.06)',
    color: type != 'cancel' ? '#ffffff' : '#380563',
    minWidth: '119px',
    height: '40px',
    padding: '10px 35px',
    borderRadius: '8px',
    border: '1px solid transparent',
    opacity: 1,
    '&:hover': {
      backgroundColor: type != 'cancel' ? "#380563" : 'rgba(56, 5, 99, 0.1)',
      opacity: type != 'cancel' ? 0.9 : 1,
    }
  };

  return (
    <Grid item>
      <Button
        onClick={onClick}
        sx={{ ...defaultStyles, ...sx }}
        style={{ width: 'auto' }}
      >
        {title}
      </Button>
    </Grid>
  );
};

export default CustomButton;
