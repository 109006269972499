import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction
} from 'react';

interface PanelNameContextType {
  panelName: string;
  setPanelName: Dispatch<SetStateAction<string>>;
}

const PanelNameContext = createContext<PanelNameContextType | undefined>(
  undefined
);

export const usePanelName = (): PanelNameContextType => {
  const context = useContext(PanelNameContext);
  if (!context) {
    throw new Error('usePanelName must be used within a PanelNameProvider');
  }
  return context;
};

export const PanelNameProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [panelName, setPanelName] = useState<string>('');

  return (
    <PanelNameContext.Provider value={{ panelName, setPanelName }}>
      {children}
    </PanelNameContext.Provider>
  );
};
