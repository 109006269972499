import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  user: any; 
  permissions: string[];
}

const initialState: UserState = {
  user: null,
  permissions: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
      state.permissions = [];
    },
  },
});

export const { setUser, setPermissions, clearUser } = authSlice.actions;

export default authSlice.reducer;
