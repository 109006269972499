import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface QuestionGroupField {
  id: number;
  type: string;
  questionGroup: {
    questionSetId: string;
    questionCount: number;
  };
  removable: boolean;
  assessmentQuestionSetId: string | null;
  questionSetId: string;
  isNew: boolean;
  name: string;
  totalQuestions: number;
}

interface QuestionGroupDataState {
  questionGroupDate: string | null;
  questionGroupFields: any[];
}

const initialState: QuestionGroupDataState = {
  questionGroupDate: null,
  questionGroupFields: []
};

const dynamicAssessmentSlice = createSlice({
  name: 'dynamicAssessment',
  initialState: initialState,
  reducers: {
    setQuestionGroupData: (state, action: PayloadAction<string | null>) => {
      state.questionGroupDate = action.payload;
    },
    addQuestionGroupField: (
      state,
      action: PayloadAction<Partial<QuestionGroupField>>
    ) => {
      const index = state.questionGroupFields.findIndex(
        (field) => field.id === action.payload.id
      );

      if (index !== -1) {
        // If the field already exists, update the totalQuestions
        state.questionGroupFields[index] = {
          ...state.questionGroupFields[index],
          ...action.payload // Update the existing field with new properties
        };
      } else {
        // If the field does not exist, add it as a new field
        state.questionGroupFields.push({
          ...action.payload,
          isNew: true // Assuming new fields should be marked as isNew
        } as QuestionGroupField);
      }
    },
    removeQuestionGroupField: (state, action: PayloadAction<number>) => {
      state.questionGroupFields = state.questionGroupFields.filter(
        (field) => field.id !== action.payload
      );
    },
    updateQuestionGroupField: (
      state,
      action: PayloadAction<QuestionGroupField>
    ) => {
      const index = state.questionGroupFields.findIndex(
        (field) => field.id === action.payload.id
      );

      if (index !== -1) {
        state.questionGroupFields[index] = {
          ...state.questionGroupFields[index],
          ...action.payload
        };
      }
    },
    clearQuestionGroupData(state) {
      state.questionGroupDate = null;
      state.questionGroupFields = [];
    }
  }
});

export const {
  setQuestionGroupData,
  addQuestionGroupField,
  removeQuestionGroupField,
  clearQuestionGroupData,
  updateQuestionGroupField
} = dynamicAssessmentSlice.actions;

export default dynamicAssessmentSlice.reducer;
