import { del, get, post, put } from 'src/services/axios.service';
import { ISubject } from './subject.interface';

export const getSubjectList = async (
  filterData: { limit?: number; offset?: number; caption?: string } = {}
): Promise<ISubject[]> => {
  try {
    const { limit, offset, caption } = filterData;
    const subjectList = await get<ISubject[]>('/subject', {
      params: { limit, offset, caption }
    });
    return subjectList;
  } catch (error) {
    throw error;
  }
};

export const createSubject = async (
  subjectData: Partial<ISubject>
): Promise<ISubject> => {
  try {
    const createdsubject = await post<ISubject>('/subject', subjectData);
    return createdsubject;
  } catch (error) {
    throw error;
  }
};

export const getSubjectById = async (
  subjectId: number
): Promise<ISubject | null> => {
  try {
    const subject = await get<ISubject>(`/subject/${subjectId}`);
    return subject;
  } catch (error) {
    throw error;
  }
};

export const updateSubject = async (
  subjectId: number,
  subjectData: ISubject
): Promise<ISubject | null> => {
  try {
    const updatedsubject = await put<ISubject>(
      `/subject/${subjectId}`,
      subjectData
    );
    return updatedsubject;
  } catch (error) {
    throw error;
  }
};

export const deleteSubject = async (subjectId: number): Promise<boolean> => {
  try {
    const deleteResponse = await del<any>(`/subject/${subjectId}`);
    return (
      deleteResponse?.status === true && deleteResponse?.data?.deleted === true
    );
  } catch (error) {
    throw error;
  }
};
