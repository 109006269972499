import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Box,
  Drawer,
  styled,
  Divider,
  useTheme,
  Grid,
  Button
} from '@mui/material';
import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
       
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

function Sidebar({ handleToggleCollapse, collapsed }) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          width: collapsed ? '80px' : '273px',
          bottom: 0,
          background: '#08070A',
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
      >
        <Scrollbar>
          <Box mt={3}>
            {collapsed ? (
              <Box mx={2}>
                <img
                  src={'/static/images/sidebar/mainLogo1.svg'}
                  style={{
                    width: '35px',
                    height: '35px',
                    margin: '0 auto',
                    display: 'block'
                  }}
                />
              </Box>
            ) : (
              <Box mx={2}>
                <img
                  src={'/static/images/sidebar/mainlogo.svg'}
                  style={{
                    width: '150px',
                    height: '92px',
                    margin: '0 auto',
                    display: 'block'
                  }}
                />
              </Box>
            )}

            <Button
              onClick={handleToggleCollapse}
              sx={{
                position: 'absolute',
                right: '0',
                minWidth: 'fit-content !important',
                padding: '0px !important'
              }}
            >
              {collapsed ? (
                <ArrowForwardIosIcon
                  sx={{
                    color: 'black',
                    position: 'fixed',
                    backgroundColor: '#fdc500',
                    height: '23px ',
                    borderRadius: '25px ',
                    padding: '5px',
                    zIndex: '99'
                  }}
                />
              ) : (
                <ArrowBackIosNewIcon
                  sx={{
                    color: 'black',
                    position: 'fixed',
                    backgroundColor: '#fdc500',
                    height: '23px ',
                    borderRadius: '25px ',
                    padding: '5px',
                    zIndex: '99'
                  }}
                />
              )}
            </Button>
          </Box>
          <Divider
            sx={{
              my: theme.spacing(2),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          />

          <Grid sx={{ padding: '0 5px' }}>
            <SidebarMenu collapsed={collapsed} />
          </Grid>
        </Scrollbar>
      </SidebarWrapper>

      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: '#08070A',
            height: '100%',
            overflowY: 'auto',
            width: '270px'
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box>
                <img
                  src={'/static/images/sidebar/mainlogo.svg'}
                  style={{
                    width: '150px',
                    height: '92px',
                    margin: '0 auto',
                    display: 'block'
                  }}
                />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />
            <SidebarMenu collapsed={collapsed} />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
