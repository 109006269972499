import { Navigate } from 'react-router-dom';

const PermissionCheck = ({ children, permissions, requiredPermissions }) => {
  const hasPermission = Array.isArray(requiredPermissions)
    ? requiredPermissions.some(permission => permissions.includes(permission))
    : permissions.includes(requiredPermissions);

  if (!hasPermission) {
    return <Navigate to="/un-accessible" />;
  }
  return children;
};

export default PermissionCheck;
