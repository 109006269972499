import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SuspenseLoader from 'src/components/SuspenseLoader';
import SidebarMenu from './layouts/SidebarLayout/SidebarMenu';
import FullScreenWrapper from './components/FullScreen/FullScreen';
import PermissionCheck from './permissionWrapper';
import BatchForm from './pages/Batch/components/BatchForm';
import BatchView from './pages/Batch/components/BatchView';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Teacher = Loader(lazy(() => import('src/pages/Teacher')));

const TeacherForm = Loader(
  lazy(() => import('src/pages/Teacher/components/TeacherForm'))
);
const LoginOTP = Loader(lazy(() => import('src/pages/Login/LoginOtp')));
const Login = Loader(lazy(() => import('src/pages/Login/Login')));

const SignUp = Loader(lazy(() => import('src/pages/SignUp')));

const TeacherRegistration = Loader(
  lazy(() => import('src/pages/Teacher/components/TeacherRegistration'))
);

const CreateNewPasswoed = Loader(
  lazy(() => import('src/pages/SignUp/component/CreateNewPasswoed'))
);

const EmailVerifyForm = Loader(
  lazy(() => import('src/pages/ForgotPassword/emailVerify'))
);

const PasswordUpdateForm = Loader(
  lazy(() => import('src/pages/ForgotPassword/passwordUpdate'))
);

const DashboardTcm = Loader(lazy(() => import('src/pages/Dashboard')));

const BusinessType = Loader(lazy(() => import('src/pages/BusinessType')));

const Business = Loader(lazy(() => import('src/pages/Business')));

const AttendAssessment = Loader(
  lazy(() => import('src/pages/Assessment/components/TeacherAttendAssessment'))
);

const BusinessForm = Loader(
  lazy(() => import('src/pages/Business/components/BusinessForm'))
);

const Grade = Loader(lazy(() => import('src/pages/Grade')));

const Question = Loader(lazy(() => import('src/pages/QuestionPool')));

const QuestionGroup = Loader(lazy(() => import('src/pages/QuestionGroup')));

const QuestionForm = Loader(
  lazy(() => import('src/pages/QuestionPool/components/QuestionForm'))
);

const QuestionGroupForm = Loader(
  lazy(() => import('src/pages/QuestionGroup/component/QuestionGroupForm'))
);

const QuestionGroupDetailView = Loader(
  lazy(
    () => import('src/pages/QuestionGroup/component/QuestionGroupDetailView')
  )
);

const QuestionSetDetailView = Loader(
  lazy(() => import('src/pages/QuestionGroup/component/QuestionSetDetailView'))
);

const QuestionBulkUpload = Loader(
  lazy(() => import('src/pages/QuestionPool/components/QuestionBulkupload'))
);

const Status404 = Loader(lazy(() => import('src/pages/Status/Status404')));

const Status500 = Loader(lazy(() => import('src/pages/Status/Status500')));

const SubAdmin = Loader(lazy(() => import('src/pages/SubAdmin')));

const SubAdminForm = Loader(
  lazy(() => import('src/pages/SubAdmin/components/SubAdminForm'))
);

const SubAdminBusiness = Loader(
  lazy(() => import('src/pages/SubAdminBusiness'))
);

const SubAdminBusinesssForm = Loader(
  lazy(() => import('src/pages/SubAdminBusiness/components/SubAdminForm'))
);

const Subject = Loader(lazy(() => import('src/pages/Subject')));

const GeneralCategory = Loader(lazy(() => import('src/pages/GeneralCategory')));

const Role = Loader(lazy(() => import('src/pages/Roles')));

const ReportData = Loader(lazy(() => import('src/pages/Report')));

const ActivityLogData = Loader(lazy(() => import('src/pages/ActivityLog')));

const RoleForm = Loader(
  lazy(() => import('src/pages/Roles/components/RolesForm'))
);

const Profile = Loader(lazy(() => import('src/pages/Profile')));

const InstructionPage = Loader(
  lazy(() => import('src/pages/Assessment/components/InstructionPage'))
);

const Assessment = Loader(lazy(() => import('src/pages/Assessment')));

const AssessmentForm = Loader(
  lazy(() => import('src/pages/Assessment/components/AssessmentForm'))
);

const AssessmentBusinessDetails = Loader(
  lazy(
    () => import('src/pages/Assessment/components/AssessmentBusinessDetails')
  )
);

const TeacherAssessmentList = Loader(
  lazy(() => import('src/pages/Assessment/components/TeacherAssessmentList'))
);

const TeacherAttendedAssessments = Loader(
  lazy(() => import('src/pages/Teacher/components/TeacherAttendedAssessments'))
);

const TeacherResult = Loader(
  lazy(() => import('src/pages/Teacher/components/AssessmentResult'))
);

const Result = Loader(
  lazy(() => import('src/pages/Assessment/components/TeacherAssessmentResult'))
);

const Batch = Loader(lazy(() => import('src/pages/Batch')));

const DynamicAssessment = Loader(
  lazy(() => import('src/pages/DynamicAssessment'))
);

const DynamicAssessmentForm = Loader(
  lazy(
    () => import('src/pages/DynamicAssessment/components/DynamicAssessmentForm')
  )
);

const StatusComingSoon = Loader(
  lazy(() => import('src/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/pages/Status/Maintenance'))
);

const routes = (permissions, userType) => [
  {
    path: '/',
    children: [
      {
        path: '',
        element: <Navigate to="login" replace />
      },
      {
        path: 'login',
        element: <LoginOTP />
      },
      {
        path: 'signin',
        element: <Login />
      },
      {
        path: 'signup',
        element: <SignUp />
      },
      {
        path: 'createNewPassword',
        element: <CreateNewPasswoed />
      },
      {
        path: 'createNewPassword/:token',
        element: <CreateNewPasswoed />
      },
      {
        path: 'teacherRegister',
        element: <TeacherRegistration />
      },
      {
        path: 'teacherRegister:token',
        element: <TeacherRegistration />
      }
    ]
  },
  {
    path: 'email-verify',
    element: <EmailVerifyForm />
  },
  {
    path: 'set-password',
    element: <PasswordUpdateForm />
  },
  {
    path: 'dashboard',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <DashboardTcm />
      },
      {
        path: ':token',
        element: <DashboardTcm />
      }
    ]
  },
  {
    path: 'business',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:business']}
          >
            <Business />
          </PermissionCheck>
        )
      },
      {
        path: 'new',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['create:business']}
          >
            <BusinessForm />
          </PermissionCheck>
        )
      },
      {
        path: ':businessId/edit',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['update:business']}
          >
            <BusinessForm />
          </PermissionCheck>
        )
      },
      {
        path: 'type',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:business-type']}
          >
            <BusinessType />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'batch',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Batch />
      },
      {
        path: 'new/:batchId',
        element: <BatchForm />
      },
      {
        path: ':batchId/edit',
        element: <BatchForm />
      },
      {
        path: ':batchId/view',
        element: <BatchView />
      }
    ]
  },
  {
    path: 'teacher',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:teacher']}
          >
            <Teacher />
          </PermissionCheck>
        )
      },
      {
        path: 'new',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['create:teacher']}
          >
            <TeacherForm />
          </PermissionCheck>
        )
      },
      {
        path: ':teacherId/edit',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['update:teacher']}
          >
            <TeacherForm />
          </PermissionCheck>
        )
      },
      {
        path: ':teacherId/attendedAssessments',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:result', 'get:report', 'get:Report']}
          >
            <TeacherAttendedAssessments />
          </PermissionCheck>
        )
      },
      {
        path: ':teacherId/attendedAssessments/:assessmentId/result',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:result']}
          >
            <TeacherResult />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'admin',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:sub-admin']}
          >
            <SubAdmin />
          </PermissionCheck>
        )
      },
      {
        path: 'new',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={[
              'create:sub-admin',
              'create:sub-admin-business'
            ]}
          >
            <SubAdminForm />
          </PermissionCheck>
        )
      },
      {
        path: ':subAdminId/edit',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={[
              'update:sub-admin',
              'update:sub-admin-business'
            ]}
          >
            <SubAdminForm />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'subject',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:subject']}
          >
            <Subject />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'grade',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:grade']}
          >
            <Grade />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'generalCategory',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:GeneralCategory']}
          >
            <GeneralCategory />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'role',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:roles']}
          >
            <Role />
          </PermissionCheck>
        )
      },
      {
        path: 'new',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['create:roles']}
          >
            <RoleForm />
          </PermissionCheck>
        )
      },
      {
        path: ':roleId/edit',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['update:roles']}
          >
            <RoleForm />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'admin-business',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:sub-admin-business']}
          >
            <SubAdminBusiness />
          </PermissionCheck>
        )
      },
      {
        path: 'new',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['create:sub-admin-business']}
          >
            <SubAdminBusinesssForm />
          </PermissionCheck>
        )
      },
      {
        path: ':subBusinessId/edit',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['update:sub-admin-business']}
          >
            <SubAdminBusinesssForm />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'profile',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['update:user']}
          >
            <Profile />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'question',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:question']}
          >
            <Question />
          </PermissionCheck>
        )
      },
      {
        path: 'new',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['create:question']}
          >
            <QuestionForm />
          </PermissionCheck>
        )
      },
      {
        path: ':questionGroupId/edit',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['update:question']}
          >
            <QuestionForm />
          </PermissionCheck>
        )
      },
      {
        path: 'bulk-upload',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['create:question']}
          >
            <QuestionBulkUpload />
          </PermissionCheck>
        )
      }
    ]
  },
  // Question-Group
  {
    path: 'question-group',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <QuestionGroup />
      },
      {
        path: 'new/:questionSetId',
        element: <QuestionGroupForm />
      },
      {
        path: ':questionSetId/edit',
        element: <QuestionGroupForm />
      },
      {
        path: ':questionGroupId/view',
        element: <QuestionGroupDetailView />
      },
      {
        path: ':questionSetId/questionSet/view',
        element: <QuestionSetDetailView />
      }

      // {
      //   path: ':questionGroupId/edit',
      //   element: (
      //     <PermissionCheck
      //       permissions={permissions}
      //       requiredPermissions={['update:question']}
      //     >
      //       <QuestionForm />
      //     </PermissionCheck>
      //   )
      // },
      // {
      //   path: 'bulk-upload',
      //   element: (
      //     <PermissionCheck
      //       permissions={permissions}
      //       requiredPermissions={['create:question']}
      //     >
      //       <QuestionBulkUpload />
      //     </PermissionCheck>
      //   )
      // }
    ]
  },
  {
    path: 'assessment',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:assessment']}
          >
            <Assessment />
          </PermissionCheck>
        )
      },
      {
        path: 'new',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['create:assessment']}
          >
            <AssessmentForm />
          </PermissionCheck>
        )
      },
      {
        path: ':assessmentId/edit',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['update:assessment']}
          >
            <AssessmentForm />
          </PermissionCheck>
        )
      },
      {
        path: ':assessmentId/view',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:assessment']}
          >
            <AssessmentBusinessDetails />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'teacherAssessment',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:attendAssessment']}
          >
            <TeacherAssessmentList />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'teacherAssessment',
    children: [
      {
        path: ':assessmentId/instruction',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['create:attendAssessment']}
          >
            <InstructionPage />
          </PermissionCheck>
        )
      },
      {
        path: ':assessmentId/test',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:attendAssessment']}
          >
            <FullScreenWrapper enterFullScreen={true}>
              <AttendAssessment />
            </FullScreenWrapper>
          </PermissionCheck>
        )
      },
      {
        path: ':assessmentId/result',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:result']}
          >
              <Result />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'report',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:Report', 'get:report']}
          >
            <ReportData />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'activityLog',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['get:activityLog']}
          >
            <ActivityLogData />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: 'dynamicAssessment',
    element: <SidebarMenu />,
    children: [
      {
        path: '',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['create:assessment']}
          >
            <DynamicAssessment />
          </PermissionCheck>
        )
      },
      {
        path: 'new',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['create:assessment']}
          >
            <DynamicAssessmentForm />
          </PermissionCheck>
        )
      },
      {
        path: ':assessmentId/edit',
        element: (
          <PermissionCheck
            permissions={permissions}
            requiredPermissions={['update:assessment']}
          >
            <DynamicAssessmentForm />
          </PermissionCheck>
        )
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default routes;
