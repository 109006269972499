import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SubjectGradeCategoryDataState {
  subjectGradeCategoryFields: any[];
}

const initialSubjectGradeCategoryState: SubjectGradeCategoryDataState = {
  subjectGradeCategoryFields: []
};

const subjectGradeCategorySlice = createSlice({
  name: 'subjectGradeCategory',
  initialState: initialSubjectGradeCategoryState,
  reducers: {
    addSubjectGradeCategoryField: (state, action: PayloadAction<any>) => {
      state.subjectGradeCategoryFields.push(action.payload);
    },
    removeSubjectGradeCategoryField: (state, action: PayloadAction<number>) => {
      state.subjectGradeCategoryFields =
        state.subjectGradeCategoryFields.filter(
          (field) => field.id !== action.payload
        );
    },
    updateSubjectGradeCategoryField: (state, action: PayloadAction<any>) => {
      const payload = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      payload.forEach((updatedField) => {
        const index = state.subjectGradeCategoryFields.findIndex(
          (field) => field.id === updatedField.id
        );

        if (index !== -1) {
          state.subjectGradeCategoryFields[index] = {
            ...state.subjectGradeCategoryFields[index],
            ...updatedField
          };
        }
      });
    },

    clearSubjectGradeCategoryData: (state) => {
      state.subjectGradeCategoryFields = [];
    }
  }
});

export const {
  addSubjectGradeCategoryField,
  removeSubjectGradeCategoryField,
  clearSubjectGradeCategoryData,
  updateSubjectGradeCategoryField
} = subjectGradeCategorySlice.actions;
export default subjectGradeCategorySlice.reducer;
