import React, { FC, useState, MouseEvent } from 'react';
import {
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Card,
  Typography,
  Button,
  Switch,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  Grid,
  Box,
  FormControl,
  Pagination,
  PaginationItem,
  Select,
  TablePagination
} from '@mui/material';
import FileEmptyIcon from '../../assets/images/no_data.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation } from 'react-router';
import { getNestedPropertyValue } from './utils/tableFunctions';

interface TableColumn {
  title: string;
  dataIndex: string;
  align?: 'left' | 'right' | 'center';
}

interface TableRowData {
  [key: string]: any;
}

interface Action {
  label: string;
  onClick: (id: string) => void;
  icon?: React.ReactNode;
  disabled?: boolean;
}

interface TableProps {
  columns: TableColumn[];
  data: TableRowData[];
  actions?: Action[];
  className?: string;
  onPageChange: (page: number) => void;
  onRowChange?: (page: number) => void;
  currentPage: number;
  pageSize: number;
  totalItems: number;
  idKey?: string;
  limit: number;
  setLimit: (limit: number) => void;
  toggledAssessments?: { [key: string]: boolean };
  isPaginationRequired?: boolean;
}

const CustomTable: FC<TableProps> = ({
  columns,
  data,
  actions,
  onPageChange,
  onRowChange,
  limit,
  currentPage,
  pageSize,
  totalItems,
  idKey = 'id',
  setLimit,
  toggledAssessments = {},
  isPaginationRequired = true
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

  const totalPages = Math.ceil(totalItems / pageSize);

  // const handlePageChange = (page: number) => {
  //   onPageChange(page);
  // };

  const handleItemsPerPageChange = (event) => {
    setLimit(Number(event.target.value));
    onPageChange(1);
  };

  const handlePageChange = (event, page) => {
    onPageChange(page);
  };

  const handlePagePerChange = (event, page: number) => {
    onRowChange(page);
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  const renderActions = (id: string) => {
    const isToggled = toggledAssessments[id];
    return (
      <TableCell align="center">
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(event) => handleMenuOpen(event, id)}
          disabled={isToggled}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && selectedRowId === id}
          onClose={handleMenuClose}
        >
          {actions?.map((action, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                action.onClick(id);
                handleMenuClose();
              }}
              disabled={isToggled || action.disabled}
            >
              {action.icon && (
                <span style={{ marginRight: 8 }}>{action.icon}</span>
              )}
              {action.label}
            </MenuItem>
          ))}
        </Menu>
      </TableCell>
    );
  };

  const renderView = (id: string) => (
    <TableCell align="center">
      <Switch
        checked={toggledAssessments[id] || false}
        onClick={() =>
          actions?.find((action) => action.label === 'View')?.onClick(id)
        }
      />
    </TableCell>
  );

  if (data?.length === 0) {
    return (
      <Card
        variant="outlined"
        sx={{
          marginTop: '-20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '300px'
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sl No.</TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.dataIndex}
                    align={column.align || 'left'}
                  >
                    {column.title}
                  </TableCell>
                ))}
                {actions?.some((action) => action.label === 'ViewPublish') && (
                  <TableCell align="center">Publish/Un-publish</TableCell>
                )}
                {actions && <TableCell align="center">Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={
                    columns.length +
                    (actions?.some((action) => action.label === 'ViewPublish')
                      ? 2
                      : 1)
                  }
                  sx={{ border: 'none' }}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      height: '100%',
                      textAlign: 'center',
                      minHeight: '300px',
                      ml: 8
                    }}
                  >
                    <Grid item>
                      <img src={FileEmptyIcon} alt="Empty" />
                    </Grid>
                    <Grid item sx={{ mt: 2 }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        No data available
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    );
  }

  return (
    <>
      <Grid mt={2}>
        <Card variant="outlined">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sl No.</TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.dataIndex}
                      align={column.align || 'left'}
                    >
                      {column.title}
                    </TableCell>
                  ))}
                  {actions?.some(
                    (action) => action.label === 'ViewPublish'
                  ) && <TableCell align="center">Publish/Un-publish</TableCell>}
                  {actions && <TableCell align="center">Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow hover key={row[idKey]} data-id={row[idKey]}>
                    <TableCell>
                      {(currentPage - 1) * pageSize + index + 1}
                    </TableCell>
                    {columns.map((column, colIndex) => (
                      <TableCell
                        sx={{ maxWidth: '100px' }}
                        key={colIndex}
                        align={column.align || 'left'}
                      >
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {column.dataIndex.includes('.')
                            ? getNestedPropertyValue(row, column.dataIndex)
                            : row[column.dataIndex]}
                        </Typography>
                      </TableCell>
                    ))}
                    {actions?.some(
                      (action) => action.label === 'ViewPublish'
                    ) && renderView(row[idKey])}
                    {actions && renderActions(row[idKey])}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {isPaginationRequired && (
          <Grid container justifyContent="flex-end" alignItems="center" mt={2}>
            <FormControl variant="outlined" size="small">
              <Select value={limit} onChange={handleItemsPerPageChange}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <Box display="flex" alignItems="center" ml={2}>
              <Typography variant="body2" sx={{ marginRight: '10px' }}>
                {`${(currentPage - 1) * limit + 1} - ${Math.min(
                  currentPage * limit,
                  pageSize
                )} of ${totalItems}`}
              </Typography>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      borderRadius: '50%',
                      '&.Mui-selected': {
                        bgcolor: 'primary.main',
                        color: 'white'
                      }
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CustomTable;
