import { del, get, post, put } from 'src/services/axios.service';
import { ITeacher, ITeacherGradeSubject } from './teacher.interface';
import { IAttendAssessment } from 'src/pages/Assessment/service/assessment.interface';

export const getTeacherList = async (
  filterData: { limit?: number; offset?: number; name?: string } = {}
): Promise<ITeacher[]> => {
  try {
    const { limit, offset, name } = filterData;
    const teacherList = await get<ITeacher[]>('/teacher', {
      params: { limit, offset, name }
    });
    return teacherList;
  } catch (error) {
    throw error;
  }
};

export const createTeacher = async (
  teacherData: Partial<ITeacher>
): Promise<ITeacher> => {
  try {
    const createdTeacher = await post<ITeacher>('/teacher', teacherData);
    return createdTeacher;
  } catch (error) {
    throw error;
  }
};

export const getTeacherById = async (
  teacherId: number
): Promise<ITeacher | null> => {
  try {
    const teacher = await get<ITeacher>(`/teacher/${teacherId}`);
    return teacher;
  } catch (error) {
    throw error;
  }
};

export const updateTeacher = async (
  teacherId: number,
  teacherData: ITeacher
): Promise<ITeacher | null> => {
  try {
    const updatedTeacher = await put<ITeacher>(
      `/teacher/${teacherId}`,
      teacherData
    );
    return updatedTeacher;
  } catch (error) {
    throw error;
  }
};

export const deleteTeacher = async (teacherId: number): Promise<boolean> => {
  try {
    const deleteResponse = await del<any>(`/teacher/${teacherId}`);
    return (
      deleteResponse?.status === true && deleteResponse?.data?.deleted === true
    );
  } catch (error) {
    throw error;
  }
};

export const createTeacherGradeSubject = async (
  teacherId: string,
  teacherGradeSubjectData: Partial<ITeacherGradeSubject>
): Promise<ITeacherGradeSubject> => {
  try {
    const createdTeacherGradeSubject = await post<ITeacherGradeSubject>(
      `/teacher/${teacherId}/grade-subject`,
      teacherGradeSubjectData
    );
    return createdTeacherGradeSubject;
  } catch (error) {
    throw error;
  }
};

export const deleteTeacherGradeSubject = async (
  teacherId: string,
  gradeSubjectId: number
): Promise<boolean> => {
  try {
    const deleteResponse = await del<any>(
      `/teacher/${teacherId}/grade-subject/${gradeSubjectId}`
    );
    return (
      deleteResponse?.status === true && deleteResponse?.data?.deleted === true
    );
  } catch (error) {
    throw error;
  }
};

export const getTeacherCompletedAssessment = async (
  pagination: { limit?: number; offset?: number } = {}
): Promise<IAttendAssessment[]> => {
  try {
    const { limit, offset } = pagination;
    const completedAttendAssessment = await get<IAttendAssessment[]>(
      '/teacher/attended-assessment',
      {
        params: { limit, offset }
      }
    );
    return completedAttendAssessment;
  } catch (error) {
    throw error;
  }
};

export const teacherBulkUpload = async (file: File): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const bulkResponse = await post<any>(`/teacher/bulk`, formData);
    return bulkResponse;
  } catch (error) {
    throw error;
  }
};

export const RegisterTeacher = async (
  teacherData: Partial<ITeacher>
): Promise<ITeacher> => {
  try {
    const createdTeacher = await post<ITeacher>(
      '/teacher/register',
      teacherData
    );
    return createdTeacher;
  } catch (error) {
    throw error;
  }
};
