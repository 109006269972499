import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Modal,
  Typography
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useQuery } from 'react-query';
import { getTeacherById } from 'src/pages/Teacher/service/teacher.service';

interface TeacherViewModelProps {
  open: boolean;
  onClose: () => void;
  selectedTeacherId: any;
}

const TeacherViewModel: React.FC<TeacherViewModelProps> = ({
  open,
  onClose,
  selectedTeacherId
}) => {
  const { data: teacherData } = useQuery<any>(
    ['teacher', selectedTeacherId],
    () => getTeacherById(parseInt(selectedTeacherId)),
    { enabled: !!selectedTeacherId }
  );

  console.log(selectedTeacherId, 'selectedTeacherId');

  const teacher = teacherData?.data?.teacher[0];

  console.log(teacher, 'teacher');

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2
      }}
    >
      <Card
        sx={{
          width: { xs: '90%', sm: '80%', md: '60%', lg: '40%' },
          maxWidth: '600px',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '80vh',
          overflowY: 'auto'
        }}
      >
        <CardContent>
          <Grid container spacing={2} p={2}>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              spacing={2}
              position="relative"
            >
              <Grid item>
                <img
                  style={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%'
                  }}
                  alt="Teacher Avatar"
                  src="/static/images/placeholders/logo/avatar_profile.png"
                />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: { xs: '18px', md: '18px' },
                    fontWeight: 700,
                    color: '#01011A'
                  }}
                >
                  {teacher?.name}
                </Typography>
                <Chip
                  label="Teacher"
                  sx={{ mt: 1, background: '#FEF5EB', color: '#F19A38' }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  cursor: 'pointer'
                }}
                onClick={onClose}
              >
                <CloseOutlinedIcon sx={{ position: 'fixed' }} />
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={1} mx={'auto'}>
              {[
                { label: 'Business', value: 'Mentric' },
                { label: 'School', value: teacher?.schoolName },
                { label: 'Contact Number', value: teacher?.contact },
                { label: 'Experience', value: teacher?.experience },
                { label: 'Email', value: teacher?.email },
                { label: 'Address', value: teacher?.address }
              ].map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={item.label === 'Address' && 'Email' ? 12 : 6}
                  key={index}
                >
                  <Typography
                    fontWeight={500}
                    fontSize={'14px'}
                    color="textSecondary"
                  >
                    {item.label || 'No Data'}
                  </Typography>
                  <Typography
                    fontWeight={700}
                    fontSize={'16px'}
                    color={item.value ? 'black' : '#c7c6c5'}
                  >
                    {item.value || 'No Data'}
                  </Typography>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12}>
              {teacher?.subjectGrade?.map((item, index) => (
                <Box key={index} mt={1.5}>
                  <Card
                    variant="outlined"
                    sx={{ justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        sx={{ padding: 2 }}
                      >
                        <Grid item container spacing={1} alignItems="center">
                          <Grid item xs={2}>
                            <Typography
                              fontWeight={500}
                              fontSize={'12px'}
                              color={'#39557E'}
                              lineHeight={'14.52px'}
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Subject:
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography
                              fontWeight={600}
                              lineHeight={'16.94px'}
                              fontSize={'14px'}
                              color={'#01011A'}
                            >
                              {item?.subject?.caption}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container spacing={1} alignItems="center">
                          <Grid item xs={2}>
                            <Typography
                              fontWeight={500}
                              fontSize={'12px'}
                              color={'#39557E'}
                              lineHeight={'14.52px'}
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Grade:
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography
                              fontWeight={600}
                              lineHeight={'16.94px'}
                              fontSize={'14px'}
                              color={'#01011A'}
                            >
                              {item?.grade?.caption}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default TeacherViewModel;
