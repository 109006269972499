import { useRef, useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LogoutIcon from '@mui/icons-material/Logout';
import { LogoutUser } from 'src/pages/Login/service/login.services';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { clearUser } from 'src/redux/slices/authSlice';
import { setToLocalStorage } from 'src/common/user.info';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: '',
    avatar: '',
    jobtitle: ''
  });

  const reduxUserData = useSelector((state: RootState) => state.auth.user);
  useEffect(() => {
    const userDataString = reduxUserData;
    if (userDataString) {
      try {
        const userData = userDataString;
        if (userData.teacher) {
          setUser({
            name: userData?.teacher?.name || 'Teacher Name',
            avatar:
              `${process.env.REACT_APP_API_URL}/file/${userData?.teacher?.profileImage}` ||
              '/static/images/avatars/teacher.jpg',
            jobtitle: userDataString?.userType
          });
        } else if (userData.business) {
          setUser({
            name: userData.business.name || 'Business Name',
            avatar: '/static/images/icons/school.jpg',
            jobtitle: userDataString?.userType
          });
        } else if (userData.admin) {
          setUser({
            name: userData.admin.name || 'Admin Name',
            avatar: '/static/images/avatars/admin.jpg',
            jobtitle: userDataString?.userType
          });
        } else if (userDataString.userType === 'SUPER-ADMIN') {
          setUser({
            name: userDataString.username || 'Admin Name',
            avatar: '/static/images/avatars/admin.jpg',
            jobtitle: userDataString?.userType
          });
        }
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    }
  }, [reduxUserData]);

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleSignOut = async () => {
    const logout = await LogoutUser();
    if (logout?.status === true) {
      localStorage.removeItem('auth_token');
      localStorage.clear();
      dispatch(clearUser());
      navigate('/login');
    }
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="circular"
          alt={user.name}
          src={user.avatar}
          sx={{ backgroundColor: '#3477F6' }}
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel sx={{ color: '#202224', fontWeight: 'medium' }}>
              {user.name}
            </UserBoxLabel>
            <UserBoxDescription
              fontWeight={'medium'}
              sx={{ fontWeight: 'medium' }}
              color={'#718EBF'}
            >
              {user.jobtitle === 'BUSINESS' ? 'ORGANIZATION' : user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar
            variant="circular"
            alt={user.name}
            src={user.avatar}
            sx={{ backgroundColor: '#3477F6' }}
          />
          <UserBoxText>
            <UserBoxLabel sx={{ color: '#202224', fontWeight: 'medium' }}>
              {user.name}
            </UserBoxLabel>
            <UserBoxDescription sx={{ fontWeight: 'medium' }} color={'#718EBF'}>
              {user.jobtitle === 'BUSINESS' ? 'ORGANIZATION' : user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          {user.jobtitle !== 'SUPER-ADMIN' && (
            <ListItem button to="/profile" component={NavLink}>
              <PermIdentityIcon fontSize="small" sx={{ color: '#202224' }} />
              <ListItemText
                sx={{ color: '#202224', fontWeight: 'medium' }}
                primary="Profile"
              />
            </ListItem>
          )}
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleSignOut}>
            <LogoutIcon sx={{ mr: 1, color: '#EB455B' }} />
            <Typography color={'#EB455B'}>Log Out</Typography>
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
