import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface BatchField {
  id: number;
  type: string;
  batch: {
    batchId: string;
  };
  removable: boolean;
  assessmentBatchId: string | null;
  isNew: boolean;
  batchId: string;
  name: string;
  totalTeachers: number;
}

interface BatchDataState {
  batchFields: any[];
}

const initialBatchState: BatchDataState = {
  batchFields: []
};

const batchSlice = createSlice({
  name: 'batch',
  initialState: initialBatchState,
  reducers: {
    addBatchField: (state, action: PayloadAction<any>) => {
      state.batchFields.push(action.payload);
    },
    removeBatchField: (state, action: PayloadAction<number>) => {
      state.batchFields = state.batchFields.filter(
        (field) => field.id !== action.payload
      );
    },
    clearBatchData: (state) => {
      state.batchFields = [];
    }
  }
});

export const { addBatchField, removeBatchField, clearBatchData } =
  batchSlice.actions;
export default batchSlice.reducer;
