import Grid from '@mui/material/Grid';
import CustomButton from 'src/shared/customButtom';

interface FormActionButtonsProps {
  handleCancel: () => void;
  handleSave: () => void;
  Id?: string;
}

const FormActionButtons: React.FC<FormActionButtonsProps> = ({
  handleCancel,
  handleSave,
  Id
}) => {
  return (
    <Grid mt={2} item container justifyContent="flex-end" spacing={1}>
      <Grid item>
        <CustomButton onClick={handleCancel} title="Cancel" type="cancel" />
      </Grid>
      <Grid item>
        <CustomButton
          onClick={handleSave}
          title={Id ? 'Update' : 'Save'}
          type="save"
        />
      </Grid>
    </Grid>
  );
};

export default FormActionButtons;
