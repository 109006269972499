import { Box, Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';

const BackButton = ({ title }) => {

  const navigate = useNavigate();

  const BackButtonContainer = styled(Box)`
  left: 4px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-top: 10px;
`;

  const BackButton = styled(IconButton)`
  font-size: 1rem;
  font-weight: bold;
  color: #5569ff;
`;

  const BackButtonText = styled(Typography)`
  margin-left: 4px;
  font-size: 1rem;
  font-weight: bold;
  color: #5569ff;
`;

  return (
    <Grid item>
      <BackButtonContainer>
        <BackButton
          onClick={() => { navigate(-1) }}
        >
          <ArrowBackIcon sx={{ fontSize: '1.75rem' }} />
        </BackButton>
        <BackButtonText>{title}</BackButtonText>
      </BackButtonContainer>
    </Grid>
  );
};

export default BackButton;
