import { DatePicker } from '@mui/lab';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Button,
  Typography,
  Slider,
  Chip,
  Autocomplete,
  TextField,
  Modal,
  Card
} from '@mui/material';
import { useState } from 'react';
import CustomButton from 'src/shared/customButtom';

const minDistance = 1;

const FilterPopOver = ({
  subjectData,
  gradeData,
  businessData,
  selectedItems,
  handleDelete,
  handleAdd,
  gradeId,
  subjectId,
  setSelectedGradeId,
  setSelectedSubjectId,
  setFormData,
  formData,
  handleSave,
  handleClose,
  cityData,
  handleCancel,
  open,
  setSelectedItems
}) => {
  const [experienceRange, setExperienceRange] = useState<number[]>([
    formData.minExperience || 0,
    formData.maxExperience || 0
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));

    if (name === 'subjectId') {
      setSelectedSubjectId(value);
    } else if (name === 'gradeId') {
      setSelectedGradeId(value);
    }
  };

  const handleExperienceChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) return;

    const newExperience = [...experienceRange];
    if (activeThumb === 0) {
      newExperience[0] = Math.min(newValue[0], newExperience[1] - minDistance);
    } else {
      newExperience[1] = Math.max(newValue[1], newExperience[0] + minDistance);
    }
    setExperienceRange(newExperience);

    setFormData((prev) => ({
      ...prev,
      minExperience: newExperience[0],
      maxExperience: newExperience[1]
    }));
  };

  const handleReset = () => {
    setFormData({
      city: '',
      businessId: '',
      subjectId: '',
      gradeId: '',
      createdOn: null,
      minExperience: 0,
      maxExperience: 0
    });
    setExperienceRange([0, 0]);
    setSelectedItems([]);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        marginLeft: '50px'
      }}
    >
      <Card
        sx={{
          maxHeight: '90vh',
          overflowY: 'auto',
          maxWidth: '800px'
        }}
      >
        <Box
          p={2}
          sx={{
            width: '350px'
          }}
        >
          <Box mb={2}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontSize: { xs: '16px', md: '18px' },
                    fontWeight: 600,
                    lineHeight: '22px',
                    marginBottom: '2px',
                    color: '#01011A'
                  }}
                >
                  Users Filter
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={11.7}>
              <DatePicker
                label="Select Date"
                views={['year', 'month', 'day']} // Show only year, month, and day
                onChange={(date) =>
                  handleChange({ target: { name: 'createdOn', value: date } })
                }
                value={formData.createdOn || null}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={11.7} display="flex" alignItems="center">
              <Autocomplete
                id="city"
                fullWidth
                options={cityData as string[]}
                value={formData.city || ''}
                autoHighlight
                disableClearable
                onChange={(event, newValue) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    city: newValue || ''
                  }));
                }}
                getOptionLabel={(option: string) => option}
                renderOption={(props, option: string) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="city"
                    label="Search and Select Location"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'city'
                    }}
                    sx={{
                      '& .MuiInputLabel-root': {
                        color: '#01011A',
                        fontWeight: 600,
                        fontSize: '14px'
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} display="flex" alignItems="center">
              <FormControl fullWidth sx={{ marginRight: 1 }}>
                <InputLabel
                  style={{
                    backgroundColor: 'white',
                    paddingLeft: '9px',
                    paddingRight: '9px',
                    color: '#01011A',
                    fontWeight: 600,
                    fontSize: '14px'
                  }}
                >
                  Select Organization
                </InputLabel>
                <Select
                  value={formData.businessId || ''}
                  onChange={handleChange}
                  name="businessId"
                >
                  {businessData?.data?.business?.map((business) => (
                    <MenuItem
                      key={business.businessId}
                      value={business.businessId}
                      sx={{ height: '50px' }}
                    >
                      {business.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} display="flex" alignItems="center">
              <FormControl fullWidth sx={{ marginRight: 1 }}>
                <InputLabel
                  style={{
                    backgroundColor: 'white',
                    paddingLeft: '9px',
                    paddingRight: '9px',
                    color: '#01011A',
                    fontWeight: 600,
                    fontSize: '14px'
                  }}
                >
                  Subject
                </InputLabel>
                <Select
                  value={subjectId || ''}
                  onChange={(e) => setSelectedSubjectId(e.target.value)}
                >
                  {subjectData?.data?.subject?.map((subject) => (
                    <MenuItem
                      key={subject.subjectId}
                      value={subject.subjectId}
                      sx={{ height: '50px' }}
                    >
                      {subject.caption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ marginRight: 1 }}>
                <InputLabel
                  style={{
                    backgroundColor: 'white',
                    paddingLeft: '9px',
                    paddingRight: '9px',
                    color: '#01011A',
                    fontWeight: 600,
                    fontSize: '14px'
                  }}
                >
                  Grade
                </InputLabel>
                <Select
                  value={gradeId || ''}
                  onChange={(e) => setSelectedGradeId(e.target.value)}
                >
                  {gradeData?.data?.grade?.map((grade) => (
                    <MenuItem key={grade.gradeId} value={grade.gradeId}>
                      {grade.caption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                sx={{ mr: 1 }}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Grid>
            <Box
              mt={2}
              ml={3}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                maxWidth: '100%',
                overflow: 'hidden',
                maxHeight: '180px',
                overflowY: 'auto'
              }}
            >
              {selectedItems?.map((item) => {
                const gradeCaption =
                  gradeData?.data?.grade.find(
                    (grade) => grade.gradeId === item.gradeId
                  )?.caption || 'Unknown Grade';
                const subjectCaption =
                  subjectData?.data?.subject.find(
                    (subject) => subject.subjectId === item.subjectId
                  )?.caption || 'Unknown Subject';
                return (
                  <Chip
                    key={`${item.subjectId}-${item.gradeId}`}
                    label={`${gradeCaption}-${subjectCaption}`}
                    onDelete={() => handleDelete(item)}
                    sx={{
                      marginRight: 1,
                      marginBottom: 1,
                      color: '#380563',
                      backgroundColor: '#3805631f',
                      whiteSpace: 'nowrap'
                    }}
                  />
                );
              })}
            </Box>
            <Grid item xs={12}>
              <Typography
                sx={{ color: '#01011A', fontWeight: 600, fontSize: '14px' }}
              >
                Year Of Experience
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                  mt: 2
                }}
              >
                <Slider
                  sx={{ width: '80%' }}
                  getAriaLabel={() => 'Minimum distance'}
                  value={experienceRange}
                  onChange={handleExperienceChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={(value) => `${value} years`}
                  disableSwap
                  min={0}
                  max={30}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '82%'
                  }}
                >
                  <Typography sx={{ fontSize: 14 }}>0 Yrs</Typography>
                  <Typography sx={{ fontSize: 14 }}>30 Yrs</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            p={2}
            mt={-2}
            mr={-1}
          >
            <Grid mt={2} item container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <CustomButton
                  onClick={handleReset}
                  title="Reset"
                  type="cancel"
                />
              </Grid>
              <Grid item>
                <CustomButton onClick={handleSave} title="Apply" type="save" />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Modal>
  );
};

export default FilterPopOver;
