import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { clearUser } from 'src/redux/slices/authSlice';
import store from 'src/redux/store';

const API_BASE_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:4000';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  withCredentials: true
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.pathname = 'login'
      toast.error('Your session has expired. Please log in again.', {
        autoClose: 3000,
        onClose: () => {
          store.dispatch(clearUser());
        }
      });
    } else if (error.response?.status === 403) {
      toast.error('Forbidden', {
        autoClose: 5000
      });
    }
    return Promise.reject(error);
  }
);

export const get = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.get<T>(url, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const post = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.post<T>(
      url,
      data,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const put = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.put<T>(
      url,
      data,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const del = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axiosInstance.delete<T>(
      url,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
