import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SubjectGradeDataState {
  subjectGradeFields: any[];
}

const initialSubjectGradeState: SubjectGradeDataState = {
  subjectGradeFields: []
};

const subjectGradeSlice = createSlice({
  name: 'subjectGrade',
  initialState: initialSubjectGradeState,
  reducers: {
    addSubjectGradeField: (state, action: PayloadAction<any>) => {
      state.subjectGradeFields.push(action.payload);
    },
    removeSubjectGradeField: (state, action: PayloadAction<number>) => {
      state.subjectGradeFields = state.subjectGradeFields.filter(
        (field) => field.id !== action.payload
      );
    },
    clearSubjectGradeData: (state) => {
      state.subjectGradeFields = [];
    }
  }
});

export const { addSubjectGradeField, removeSubjectGradeField, clearSubjectGradeData } =
  subjectGradeSlice.actions;
export default subjectGradeSlice.reducer;
