import { del, get, post, put } from 'src/services/axios.service';
import { IBatch } from './batch.interface';

export const getBatchList = async (
  filterData: {
    limit?: number;
    offset?: number;
    name?: string;
    batchId?: number;
  } = {}
): Promise<IBatch[]> => {
  try {
    const { limit, offset, name, batchId } = filterData;
    const businessList = await get<IBatch[]>('/batch', {
      params: { limit, offset, name, batchId }
    });
    return businessList;
  } catch (error) {
    throw error;
  }
};

export const getTeacherList = async (
  requestData: Partial<any>
): Promise<any[]> => {
  try {
    const teacherList = await post<any[]>('/teacher/list', requestData);
    return teacherList;
  } catch (error) {
    throw error;
  }
};

export const createBatch = async (
  batchData: Partial<IBatch>
): Promise<IBatch> => {
  try {
    const createBatch = await post<IBatch>('/batch', batchData);
    return createBatch;
  } catch (error) {
    throw error;
  }
};

export const getBatchById = async (
  batchId: number,
  filterData: { limit?: number; offset?: number; name?: string } = {}
): Promise<IBatch | null> => {
  try {
    const { limit, offset, name } = filterData;
    const batch = await post<IBatch>(`/batch/${batchId}/list`, {
      batchId,
      limit,
      offset,
      name
    });
    return batch;
  } catch (error) {
    throw error;
  }
};

export const getBatchByIdList = async (
  batchId: number,
  requestData: Partial<any>
): Promise<any[]> => {
  try {
    const batchList = await post<IBatch[]>(
      `/batch/${batchId}/list`,
      requestData
    );
    return batchList;
  } catch (error) {
    throw error;
  }
};

export const createListBatch = async (
  batchId: number,
  userIds: { userId: number }[]
): Promise<IBatch> => {
  try {
    const createBatch = await post<IBatch>(`/batch/${batchId}`, { userIds });
    return createBatch;
  } catch (error) {
    throw error;
  }
};

export const deleteBatch = async (batchId: number): Promise<boolean> => {
  try {
    const deleteResponse = await del<any>(`/batch/${batchId}`);
    return (
      deleteResponse?.status === true && deleteResponse?.data?.deleted === true
    );
  } catch (error) {
    throw error;
  }
};

export const deleteBatchUser = async (
  userBatchId: number
): Promise<boolean> => {
  try {
    const deleteResponse = await del<any>(`/batch/batch-user/${userBatchId}`);
    return (
      deleteResponse?.status === true && deleteResponse?.data?.deleted === true
    );
  } catch (error) {
    throw error;
  }
};

export const getCity = async (): Promise<any[]> => {
  try {
    const city = await get<any[]>('/teacher/city', {});
    return city;
  } catch (error) {
    throw error;
  }
};

export const updateBatch = async (
  batchId: number,
  batchData: IBatch
): Promise<IBatch | null> => {
  try {
    const updatedGrade = await put<IBatch>(`/batch/${batchId}`, batchData);
    return updatedGrade;
  } catch (error) {
    throw error;
  }
};
