import React from 'react';
import {
  Modal,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  InputAdornment
} from '@mui/material';
import CustomButton from './customButtom';

interface BatchModelProps {
  open: boolean;
  handleCancel: () => void;
  handleSave: () => void;
  title: string;
  subtitle: string;
  formData: {
    name: string;
    description: string;
  };
  handleInputChange: (field: string, value: string) => void;
  handleBlur: (field: string) => void;
  touchedFields: {
    name: boolean;
    description: boolean;
  };
  inputLabelProps?: any;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  buttonText: string;
  captionFieldLabel: string;
  captionFieldLabel1: string;
}

const BatchModel: React.FC<BatchModelProps> = ({
  open,
  handleCancel,
  handleSave,
  title,
  subtitle,
  formData,
  handleInputChange,
  handleBlur,
  touchedFields,
  inputLabelProps,
  inputProps,
  buttonText,
  captionFieldLabel,
  captionFieldLabel1
}) => {
  return (
    <Modal
      open={open}
      onClose={handleCancel}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Card sx={{ width: '400px' }}>
        <CardContent>
          <Grid>
            <Typography
              variant="h6"
              style={{ fontSize: '18px', color: '#01011A', fontWeight: 'bold' }}
            >
              {title}
            </Typography>
            <Typography
              fontSize={12}
              color={'#39557E'}
              fontWeight={'medium'}
              style={{ marginTop: '0' }}
            >
              {subtitle}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={4}>
            <TextField
              label={captionFieldLabel}
              value={formData?.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              fullWidth
              onBlur={() => handleBlur('name')}
              error={touchedFields.name && !formData.name.trim()}
              helperText={
                touchedFields.name && !formData.name.trim()
                  ? 'This field is required'
                  : ''
              }
              required
              inputProps={{ minLength: 1, maxLength: 120 }}
              InputLabelProps={inputLabelProps}
            />
          </Grid>
          <Grid item xs={12} mt={4}>
            <TextField
              label={captionFieldLabel1}
              value={formData?.description || ''}
              onChange={(e) => handleInputChange('description', e.target.value)}
              fullWidth
              multiline
              minRows={3}
              onBlur={() => handleBlur('description')}
              error={touchedFields.description && !formData?.description?.trim()}
              helperText={
                touchedFields.description && !formData?.description?.trim()
                  ? 'This field is required'
                  : ''
              }
              required
              inputProps={{ minLength: 1, maxLength: 250 }}
              InputLabelProps={inputLabelProps}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      position: 'absolute',
                      bottom: 10,
                      right: 0,
                      color: '#858585',
                      fontWeight: 600,
                      fontSize: '12px'
                    }}
                  >
                    {250 - formData?.description?.length || 250}/250
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={1} mt={2}>
            <Grid item>
              <CustomButton
                onClick={handleCancel}
                title="Cancel"
                type="cancel"
              />
            </Grid>
            <Grid item ml={1}>
              <CustomButton
                onClick={handleSave}
                title={buttonText}
                type="save"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default BatchModel;
