import { del, get, post, put } from 'src/services/axios.service';
import { IGrade } from './grade.interface';

export const getGradeList = async (
  filterData: { limit?: number; offset?: number; caption?: string } = {}
): Promise<IGrade[]> => {
  try {
    const { limit, offset, caption } = filterData;
    const gradeList = await get<IGrade[]>('/grade', {
      params: { limit, offset, caption }
    });
    return gradeList;
  } catch (error) {
    throw error;
  }
};

export const createGrade = async (
  gradeData: Partial<IGrade>
): Promise<IGrade> => {
  try {
    const createdGrade = await post<IGrade>('/grade', gradeData);
    return createdGrade;
  } catch (error) {
    throw error;
  }
};

export const getGradeById = async (gradeId: number): Promise<IGrade | null> => {
  try {
    const grade = await get<IGrade>(`/grade/${gradeId}`);
    return grade;
  } catch (error) {
    throw error;
  }
};

export const updateGrade = async (
  gradeId: number,
  gradeData: IGrade
): Promise<IGrade | null> => {
  try {
    const updatedGrade = await put<IGrade>(`/grade/${gradeId}`, gradeData);
    return updatedGrade;
  } catch (error) {
    throw error;
  }
};

export const deleteGrade = async (gradeId: number): Promise<boolean> => {
  try {
    const deleteResponse = await del<any>(`/grade/${gradeId}`);
    return (
      deleteResponse?.status === true && deleteResponse?.data?.deleted === true
    );
  } catch (error) {
    throw error;
  }
};
