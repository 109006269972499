import { get, post, put } from 'src/services/axios.service';
import { ILogin } from './login.interface';
import { ILogout } from './logout.interface';

export const getLoginUser = async (): Promise<ILogin | null> => {
  try {
    const loginUser = await get<ILogin>('/me', {});
    return loginUser;
  } catch (error) {
    throw error;
  }
};

export const LoginUser = async (formData: ILogin) => {
  try {
    const loginUser = await post<ILogin>('/login', formData);
    return loginUser;
  } catch (error) {
    throw error;
  }
};



export const LogoutUser = async (): Promise<ILogout | null> => {
  try {
    return await get<ILogout>('/logout', {});
  } catch (error) {
    throw error;
  }
};

export const emailVerification = async (
  userData: Partial<ILogin>
): Promise<ILogin> => {
  try {
    const createdUser = await post<ILogin>('/user/reset-password', userData);
    return createdUser;
  } catch (error) {
    throw error;
  }
};

export const passwordUpdate = async (userData: {
  token: string;
  user: Partial<ILogin>;
}): Promise<ILogin> => {
  try {
    const createdUser = await put<ILogin>(`/verify/${userData.token}`, {
      newPassword: userData.user.newPassword
    });
    return createdUser;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (
  userId: number,
  password: string
): Promise<any> => {
  try {
    const updateUser = await put(`/user/${userId}`, {
      password: password
    });
    return updateUser;
  } catch (error) {
    throw error;
  }
};
