export interface IBatch {
  data?: any;
  batchId?: number;
  name: string;
  description: string;
  createdOn?: string;
}

export interface ITeacherListFilter {
  teacherId?: number;
  businessId?: number;
  name?: string;
  email?: string;
  contact?: string;
  city?: string;
  schoolName?: string;
  minExperience?: number;
  maxExperience?: number;
  createdOn?: string;
  subjectGrade?: {
    subjectId: number;
    gradeId: number;
  }[];
  teacherData?: any;
}

export const InitialFormData: IBatch = {
  name: ' ',
  description: null
};

export const InitialTouchedFields = {
  name: false,
  description: false
};

export const TouchedFields = {
  name: true,
  description: true
};
