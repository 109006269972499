import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FiltersState {
  assessmentDateFilter: string | null;
}

const initialState: FiltersState = {
  assessmentDateFilter: null
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setAssessmentDateFilter: (state, action: PayloadAction<string | null>) => {
      state.assessmentDateFilter = action.payload;
    },
    clearAssessmentDateFilter(state) {
      state.assessmentDateFilter = null;
    }
  }
});

export const { setAssessmentDateFilter, clearAssessmentDateFilter } =
  filtersSlice.actions;
export default filtersSlice.reducer;
