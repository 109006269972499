import React, { useEffect, useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  Checkbox,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Menu,
  MenuItem
} from '@mui/material';
import {
  createListBatch,
  deleteBatchUser,
  getBatchByIdList,
  getBatchList,
  getCity,
  getTeacherList,
  updateBatch
} from '../service/batch.services';
import { useQuery, useQueryClient } from 'react-query';
import SearchIcon from '@mui/icons-material/Search';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useParams } from 'react-router';
import FormActionButtons from 'src/components/FormActionButton';
import FilterPopOver from './FilterPopOver';
import { getSubjectList } from 'src/pages/Subject/service/subject.services';
import { getGradeList } from 'src/pages/Grade/service/grade.service';
import { getBusinessList } from 'src/pages/Business/service/business.services';
import filter from '../../../assets/images/filteri.svg';
import FileEmptyIcon from '../../../assets/images/no_data.svg';
import action from '../../../assets/images/action.png';

import {
  IBatch,
  ITeacherListFilter,
  InitialFormData,
  InitialTouchedFields
} from '../service/batch.interface';
import BatchFilterPopOver from './BatchFilterPopOver';
import BatchModel from 'src/shared/batchModel';
import { toast } from 'react-toastify';
import SuspenseLoader from 'src/components/SuspenseLoader';
import dayjs from 'dayjs';

const TransferList = () => {
  const [leftItems, setLeftItems] = useState<any[]>([]);
  const [rightItems, setRightItems] = useState<any[]>([]);
  const [selectedLeft, setSelectedLeft] = useState<any[]>([]);
  const [selectedRight, setSelectedRight] = useState<any[]>([]);
  const queryClient = useQueryClient();
  let { batchId } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    createdOn: '',
    teacherId: '',
    businessId: '',
    name: '',
    email: '',
    contact: '',
    city: '',
    subjectGrade: '',
    schoolName: '',
    minExperience: '',
    maxExperience: ''
  });
  const [batchFormData, bnatchSetFormData] = useState({
    createdOn: '',
    teacherId: '',
    businessId: '',
    name: '',
    email: '',
    contact: '',
    city: '',
    subjectGrade: '',
    schoolName: '',
    minExperience: '',
    maxExperience: ''
  });
  const [selectedItems, setSelectedItems] = useState([]);

  const [initialRightSideCompleteData, setInitialRightSideComplete] = useState(
    []
  );
  const [selectedBatchItems, setSelectedBathcItems] = useState([]);
  const [gradeId, setSelectedGradeId] = useState(null);
  const [subjectId, setSelectedSubjectId] = useState(null);

  const [batchGradeId, setBatchGradeId] = useState(null);
  const [bathcSubjectId, setBathcSubjectId] = useState(null);

  const [filterData, setFilteredData] = useState<Partial<ITeacherListFilter>>(
    {}
  );
  const [filterBatchData, setFilteredBatchData] = useState<
    Partial<ITeacherListFilter>
  >({});
  const [teacherName, setTeacherName] = useState('');
  const [batchName, setBatchName] = useState('');
  const [teacherNameFilter, setTeacherNameFilter] = useState('');
  const [isLoadingSave, setLoadingSave] = useState(false);

  const { data: subjectData } = useQuery<any>(['subject'], () =>
    getSubjectList({ limit: 0, offset: 0 })
  );

  const { data: gradeData } = useQuery<any>(['grade'], () =>
    getGradeList({ limit: 0, offset: 0 })
  );

  const { data: businessData } = useQuery<any>(['business'], () =>
    getBusinessList({
      limit: 0,
      offset: 0
    })
  );

  const handleAddItem = () => {
    if (gradeId && subjectId) {
      const newItem = { gradeId, subjectId };

      const isDuplicate = selectedItems.some(
        (item) =>
          item.gradeId === newItem.gradeId &&
          item.subjectId === newItem.subjectId
      );

      if (!isDuplicate) {
        setSelectedItems([...selectedItems, newItem]);
      }
      setSelectedGradeId(null);
      setSelectedSubjectId(null);
    }
  };

  const handleAddBatchItem = () => {
    if (batchGradeId && bathcSubjectId) {
      const newItems = { gradeId: batchGradeId, subjectId: bathcSubjectId };

      const isDuplicate = selectedBatchItems.some(
        (item) =>
          item.gradeId === newItems.gradeId &&
          item.subjectId === newItems.subjectId
      );

      if (!isDuplicate) {
        setSelectedBathcItems([...selectedBatchItems, newItems]);
      }

      setBatchGradeId(null);
      setBathcSubjectId(null);
    }
  };

  const handleDelete = (itemToDelete) => {
    setSelectedItems(
      selectedItems.filter(
        (item) =>
          item.subjectId !== itemToDelete.subjectId ||
          item.gradeId !== itemToDelete.gradeId
      )
    );
  };

  const handleDeleteBathcFiltered = (itemToDelete) => {
    setSelectedBathcItems(
      selectedBatchItems.filter(
        (item) =>
          item.subjectId !== itemToDelete.subjectId ||
          item.gradeId !== itemToDelete.gradeId
      )
    );
  };

  const { data: batchListById } = useQuery<any>(
    ['batch', batchName, batchId],
    () =>
      getBatchList({
        batchId: parseInt(batchId)
      })
  );

  const batchListData = batchListById?.data?.batch[0];

  const setStartTimeToMidnight = (dateTime: string): string => {
    const date = new Date(dateTime);
    const utcMidnight = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0,
        0,
        0
      )
    );
    const formattedDate = utcMidnight.toISOString().split('.')[0] + 'Z';
    return formattedDate;
  };

  const { data: responseData } = useQuery<any>(
    ['teacherList', { ...filterData, name: teacherName }],
    () =>
      getTeacherList({
        ...filterData,
        name: teacherName ? `%${teacherName}%` : '',
        limit: 0
      }),
    {
      onSuccess: (data) => {
        if (data?.data?.teacher) {
          const filteredLeftItems = data.data.teacher.filter(
            (teacher: any) =>
              !rightItems.some(
                (rightItem) => rightItem.userId === teacher.userId
              )
          );
          setLeftItems(filteredLeftItems);
        }
      }
    }
  );

  const [filteredItems, setFilteredItems] = useState([]);

  const handleFilterSave = () => {
    const requestData: Partial<ITeacherListFilter> = {};

    if (formData.createdOn) {
      requestData.createdOn = setStartTimeToMidnight(formData.createdOn);
    }

    if (formData.teacherId) {
      requestData.teacherId = Number(formData.teacherId);
    }

    if (formData.businessId) {
      requestData.businessId = Number(formData.businessId);
    }

    if (formData.minExperience) {
      requestData.minExperience = Number(formData.minExperience);
    }

    if (formData.maxExperience) {
      requestData.maxExperience = Number(formData.maxExperience);
    }

    if (requestData.businessId === undefined || isNaN(requestData.businessId)) {
      requestData.businessId = undefined;
    }

    if (formData.city) {
      requestData.city = formData.city || undefined;
    }

    const subjectGradeItems = selectedItems
      .filter((item) => item.subjectId && item.gradeId)
      .map((item) => ({
        subjectId: Number(item.subjectId),
        gradeId: Number(item.gradeId)
      }));

    if (subjectGradeItems.length > 0) {
      requestData.subjectGrade = subjectGradeItems;
    }

    setFilteredData(requestData);
    queryClient.refetchQueries(['teacherList', requestData], { exact: true });

    setInitialDataSet(true);
    handleClose();
  };

  const handleBatchFilterSave = () => {
    const newRequestData: Partial<ITeacherListFilter> = {
      ...batchFormData,
      createdOn: batchFormData.createdOn ? batchFormData.createdOn : null,
      teacherId: batchFormData.teacherId
        ? parseInt(batchFormData.teacherId)
        : undefined,
      businessId:
        batchFormData.businessId && !isNaN(parseInt(batchFormData.businessId))
          ? parseInt(batchFormData.businessId)
          : undefined,
      minExperience: batchFormData.minExperience
        ? parseInt(batchFormData.minExperience)
        : undefined,
      maxExperience: batchFormData.maxExperience
        ? parseInt(batchFormData.maxExperience)
        : undefined,
      subjectGrade: selectedBatchItems.map((item) => ({
        subjectId: Number(item.subjectId),
        gradeId: Number(item.gradeId)
      }))
    };

    setInitialDataSet(true);
    setFilteredBatchData(newRequestData);

    handleBatchClose();
  };

  const applyFilters = (items) => {
    return items.filter((item) => {
      const itemBusinessId =
        item?.business?.businessId || item?.businessId || '';
      const city = item?.city || '';
      const itemCreatedOn = item?.createdOn
        ? new Date(item.createdOn).toISOString().split('T')[0]
        : '';
      const itemGradeId = item?.subjectGrade?.[0]?.grade?.gradeId || '';
      const itemSubjectId = item?.subjectGrade?.[0]?.subject?.subjectId || '';
      const itemExperience = item?.experience || 0;

      const filterBusinessId = filterBatchData?.businessId || '';
      const filterCity = filterBatchData?.city || '';

      const filterCreatedOn = filterBatchData?.createdOn
        ? dayjs(filterBatchData.createdOn).format('YYYY-MM-DD')
        : '';

      const filterGradeIds =
        filterBatchData?.subjectGrade?.map((sg) => sg.gradeId) || [];
      const filterSubjectIds =
        filterBatchData?.subjectGrade?.map((sg) => sg.subjectId) || [];

      const filterMinExperience = filterBatchData?.minExperience || 0;
      const filterMaxExperience =
        filterBatchData?.maxExperience || Number.MAX_VALUE;

      const matchesBusinessId = filterBusinessId
        ? itemBusinessId === filterBusinessId
        : true;
      const matchesCity = filterCity ? city === filterCity : true;
      const matchesCreatedOn = filterCreatedOn
        ? itemCreatedOn === filterCreatedOn
        : true;

      const matchesGradeId =
        filterGradeIds.length > 0 ? filterGradeIds.includes(itemGradeId) : true;

      const matchesSubjectId =
        filterSubjectIds.length > 0
          ? filterSubjectIds.includes(itemSubjectId)
          : true;
      const matchesExperience =
        itemExperience >= filterMinExperience &&
        itemExperience <= filterMaxExperience;

      return (
        matchesBusinessId &&
        matchesCity &&
        matchesCreatedOn &&
        matchesGradeId &&
        matchesSubjectId &&
        matchesExperience
      );
    });
  };

  const handleBatchSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase() || '';
    setTeacherNameFilter(searchTerm);

    let filteredItems = applyFilters(rightItems);

    if (searchTerm === '') {
      setFilteredItems(filteredItems);
    } else {
      const newFilteredItems = filteredItems.filter((item) =>
        item.name.toLowerCase().includes(searchTerm)
      );
      setFilteredItems(newFilteredItems);
    }
  };

  useEffect(() => {
    const filtered = applyFilters(rightItems);
    setFilteredItems(filtered);
  }, [filterBatchData, rightItems]);

  const displayItems = Object.values(filterBatchData).some((value) => value)
    ? filteredItems
    : filteredItems;

  const { data: city } = useQuery<any>(['city'], () => getCity());

  const cityData = city?.data?.city[0]?.cities;

  useEffect(() => {
    if (responseData?.data?.teacher) {
      setLeftItems(responseData?.data?.teacher);
    }
  }, [responseData?.data?.teacher]);

  const { data: getByIdData } = useQuery<any>(
    ['batchById', batchId],
    () =>
      getBatchByIdList(parseInt(batchId), {
        limit: 0
      }),
    { enabled: !!batchId }
  );

  const [initialDataSet, setInitialDataSet] = useState(false);

  useEffect(() => {
    if (!initialDataSet && getByIdData?.data.batch.length) {
      setInitialRightSideComplete(getByIdData?.data.batch);
      setInitialDataSet(false);
    }
  }, [getByIdData, initialDataSet]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBatchClick = () => {
    setRightOpen(true);
  };

  const handleBatchClose = () => {
    setRightOpen(false);
  };

  const [open, setOpen] = useState(false);
  const [openRight, setRightOpen] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [editBatchId, editSetBatchId] = useState<string>('');

  const [modelFormData, modelSetFormData] = useState(InitialFormData);
  const [touchedFields, setTouchedFields] = useState(InitialTouchedFields);

  const handleInputChange = (name: keyof IBatch, value: string | number) => {
    modelSetFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));

    setTouchedFields((prevState) => ({
      ...prevState,
      [name]: true
    }));
  };

  const handleBlur = (fieldName) => {
    setTouchedFields((prevState) => ({
      ...prevState,
      [fieldName]: true
    }));
  };

  const handleBatchEdit = () => {
    setModalOpen(true);
    editSetBatchId(batchId);
  };

  useEffect(() => {
    if (editBatchId) {
      setModalOpen(true);
      if (batchListById) {
        modelSetFormData(batchListById.data.batch[0]);
      }
    }
  }, [editBatchId]);

  const handleBatchUpdate = async () => {
    setLoadingSave(true);

    const isEmptyField = Object.keys(modelFormData).some(
      (field) => !modelFormData[field]
    );

    if (isEmptyField) {
      setLoadingSave(false);
      return;
    }

    try {
      if (editBatchId) {
        await updateBatch(parseInt(editBatchId), modelFormData);
        navigate(`/batch/${batchId}/edit`, {
          replace: true,
          state: { reload: true }
        });
        setModalOpen(false);
        queryClient.refetchQueries('batch');
      } else {
        toast.error('Grade ID is missing.');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message[0]);
    } finally {
      setLoadingSave(false);
    }
  };

  useEffect(() => {
    if (responseData?.data?.teacher && rightItems) {
      if (leftItems && Array.isArray(leftItems)) {
        const filteredLeftItems = responseData?.data?.teacher.filter(
          (teacher: any) =>
            !rightItems.some(
              (rightItem) => rightItem.userId === teacher.userId
            ) &&
            !leftItems.some(
              (leftItem: any) => leftItem.userId === teacher.userId
            )
        );
        setLeftItems([...leftItems, ...filteredLeftItems]);
      } else {
        const filteredLeftItems = responseData?.data?.teacher.filter(
          (data: any) =>
            !rightItems.some((rightItem) => rightItem.userId === data.userId)
        );
        if (filteredLeftItems.length > 0) {
          setLeftItems(filteredLeftItems);
        }
      }
    }
  }, [rightItems, getByIdData, responseData]);

  useEffect(() => {
    if (getByIdData?.data?.batch && rightItems.length === 0) {
      const uniqueBatchUsers = new Map<number, any>();
      getByIdData.data.batch.forEach((item: any) => {
        if (!uniqueBatchUsers.has(item.userId)) {
          uniqueBatchUsers.set(item.userId, {
            ...item.teacherData,
            address: item.teacherData.address,
            business: item.teacherData.businessId,
            city: item.teacherData.city,
            contact: item.teacherData.contact,
            createdOn: item.teacherData.createdOn,
            district: item.teacherData.district,
            email: item.teacherData.email,
            experience: item.teacherData.experience,
            name: item.teacherData.name,
            pincode: item.teacherData.pincode,
            state: item.teacherData.state,
            teacherId: item.teacherData.teacherId,
            userId: item.teacherData.userId,
            userBatchId: item.userBatchId
          });
        }
      });

      const filteredRightItemsWithDetails = Array.from(
        uniqueBatchUsers.values()
      );
      setRightItems(filteredRightItemsWithDetails);

      const filteredLeftItems = responseData?.data?.teacher.filter(
        (teacher: any) => !uniqueBatchUsers.has(teacher.userId)
      );

      setLeftItems(filteredLeftItems);
    } else {
      const uniqueBatchUsers = new Map<number, any>();
      rightItems.forEach((item: any) => {
        if (!uniqueBatchUsers.has(item.userId)) {
          uniqueBatchUsers.set(item.userId, {
            address: item.address,
            business: item.businessId,
            city: item.city,
            contact: item.contact,
            createdOn: item.createdOn,
            district: item.district,
            email: item.email,
            experience: item.experience,
            name: item.name,
            pincode: item.pincode,
            state: item.state,
            teacherId: item.teacherId,
            userId: item.userId,
            userBatchId: item.userBatchId
          });
        }
      });

      const filteredRightItemsWithDetails = Array.from(
        uniqueBatchUsers.values()
      );
      setRightItems(filteredRightItemsWithDetails);

      const filteredLeftItems = getByIdData?.data?.batch.filter(
        (user: any) => !uniqueBatchUsers.has(user.userId)
      );
      setLeftItems(filteredLeftItems);
    }
  }, [getByIdData]);

  const onSearchChange = (name: string, value: string) => {
    if (name === 'teacherName') {
      setTeacherName(value);
    } else if (name === 'batchName') {
      setBatchName(value);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInitialDataSet(true);
    const { name, value } = event.target;
    const trimmedValue = value.trim();
    onSearchChange(name, trimmedValue);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleModelCancel = () => {
    setModalOpen(false);
  };

  const handleSave = async () => {
    try {
      await Promise.all(
        leftItems.map(async (data) => {
          if (data.userBatchId) {
            try {
              const isDeleted = await deleteBatchUser(data.userBatchId);
              if (isDeleted) {
                console.log(
                  `userBatchId ${data.userBatchId} deleted successfully`
                );
              } else {
                console.log(
                  `Failed to delete QuestionSetQuestionId ${data.userBatchId}`
                );
              }
            } catch (error) {
              console.error(
                `Error deleting QuestionSetQuestionId ${data.userBatchId}`,
                error
              );
            }
          }
        })
      );

      const existingUserIds = rightItems.map((item) => item?.userBatchId) || [];

      const filteredUserIds = rightItems
        .filter((data) => !data?.userBatchId)
        .map((data) => ({
          userId: data?.userId
        }));

      if (filteredUserIds.length === 0 && existingUserIds.length === 0) {
        toast.info('No Users available to add.');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
      if (filteredUserIds.length > 0) {
        await createListBatch(parseInt(batchId), filteredUserIds);
      }
      window.location.replace('/batch');
    } catch (error) {
      console.error('Error creating batch:', error);
    }
  };

  const moveToRight = () => {
    setInitialDataSet(false);
    const itemsToMove = leftItems.filter((item) => selectedLeft.includes(item));
    setRightItems((prev) => [...prev, ...itemsToMove]);
    setInitialRightSideComplete((prev) => [...prev, ...itemsToMove]);
    setLeftItems((prev) => prev.filter((item) => !selectedLeft.includes(item)));
    setSelectedLeft([]);
    setInitialDataSet(true);
  };

  const handleSelectAllRight = () => {
    if (selectedRight.length === rightItems.length) {
      setSelectedRight([]);
    } else {
      setSelectedRight(displayItems);
    }
  };

  const moveToLeft = async () => {
    const itemsToMove = rightItems.filter((item) =>
      selectedRight.includes(item)
    );
    setInitialDataSet(false);
    setInitialDataSet(false);
    setLoadingSave(true);
    setFilteredBatchData({});
    setSelectedBathcItems([]);

    try {
      setTeacherNameFilter('');

      const updatedRightItems = rightItems.filter(
        (item) => !selectedRight.includes(item)
      );
      setRightItems(updatedRightItems);
      setInitialRightSideComplete(updatedRightItems);

      const updatedLeftItems = [
        ...leftItems,
        ...itemsToMove.map((item) => ({
          ...item,
          userBatchId: item.userBatchId
        }))
      ];
      setLeftItems(updatedLeftItems);
      setTeacherNameFilter('');
      setAnchorMenuEl(null);
      setSelectedRight([]);
    } catch (error) {
      toast.error('Error removing items:', error);
    } finally {
      setLoadingSave(false);
    }
  };

  const handleRemoveClick = async (item: any) => {
    const { userBatchId, teacherId } = item;

    setLoadingSave(true);
    setInitialDataSet(false);

    try {
      if (userBatchId) {
        setTeacherNameFilter('');
        const updatedRightItems = rightItems.filter(
          (i) => i.teacherId !== teacherId
        );
        setRightItems(updatedRightItems);
        setInitialRightSideComplete(updatedRightItems);

        const updatedLeftItems = responseData?.data?.teacher
          .filter(
            (teacher: any) =>
              !updatedRightItems.some(
                (rightItem) => rightItem.userId === teacher.userId
              )
          )
          .map((teacher: any) => ({
            ...teacher,
            address: teacher.address,
            business: teacher.business.businessId,
            city: teacher.city,
            contact: teacher.contact,
            createdOn: teacher.createdOn,
            district: teacher.district,
            email: teacher.email,
            experience: teacher.experience,
            name: teacher.name,
            pincode: teacher.pincode,
            state: teacher.state,
            teacherId: teacher.teacherId,
            userId: teacher.userId,
            userBatchId: userBatchId
          }));
        setLeftItems(updatedLeftItems);
        setAnchorMenuEl(null);
      } else {
        const updatedRightItems = rightItems.filter(
          (i) => i.teacherId !== teacherId
        );
        setRightItems(updatedRightItems);
        setInitialRightSideComplete(updatedRightItems);
        const updatedLeftItems = responseData?.data?.teacher.filter(
          (teacher: any) =>
            !updatedRightItems.some(
              (rightItem) => rightItem.userId === teacher.userId
            )
        );
        setLeftItems(updatedLeftItems);
        setTeacherNameFilter('');
      }
    } catch (error) {
      toast.error('Error removing item:', error);
    } finally {
      setLoadingSave(false);
    }
  };

  const toggleSelectionLeft = (item: any) => {
    setSelectedLeft((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };

  const toggleSelectionRight = (item: any) => {
    setSelectedRight((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };

  const handleSelectAllLeft = () => {
    if (selectedLeft.length === leftItems.length) {
      setSelectedLeft([]);
    } else {
      setSelectedLeft(leftItems);
    }
  };

  const handleUnselectAllRight = () => {
    setSelectedRight([]);
  };

  const countOfTeacherFilter = Object.values(filterData).filter((value) => {
    return (
      value !== undefined &&
      value !== '' &&
      value !== null &&
      !(Array.isArray(value) && value.length === 0)
    );
  }).length;

  const countOfBatchTeacherFilter = Object.values(filterBatchData).filter(
    (value) => {
      return (
        value !== undefined &&
        value !== '' &&
        value !== null &&
        !(Array.isArray(value) && value.length === 0)
      );
    }
  ).length;

  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);

  const handleMenuClick = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  return (
    <>
      {isLoadingSave ? (
        <SuspenseLoader />
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid container p={2} pb={1} spacing={1}>
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardContent>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    mb={2}
                    mt={'10px'}
                  >
                    <img
                      style={{
                        width: '29px',
                        height: '29px',
                        marginRight: '8px'
                      }}
                      alt="BTC"
                      src="/static/images/placeholders/logo/3User.svg"
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: '16px', md: '18px' },
                          fontWeight: 600,
                          lineHeight: '22px',
                          color: '#01011A'
                        }}
                      >
                        Teacher List
                      </Typography>
                      <Typography
                        sx={{
                          color: 'white',
                          backgroundColor: '#380563',
                          padding: '4px 10px',
                          borderRadius: '48px',
                          marginLeft: '10px'
                        }}
                      >
                        {`${leftItems?.length}`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid display={'flex'}>
                    <Grid item xs={10.5} mr={2}>
                      <TextField
                        variant="outlined"
                        placeholder="Search..."
                        name="teacherName"
                        value={teacherName}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          sx: {
                            paddingRight: 0,
                            height: { xs: '35px', md: '43px' },
                            width: '100%'
                          }
                        }}
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    <Grid
                      item
                      ml={1}
                      xs={1}
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      <Button
                        sx={{
                          padding: '0 !important',
                          background: 'none !important',
                          justifyContent: 'end'
                        }}
                        onClick={handleClick}
                      >
                        <img
                          src={filter}
                          alt="Filter icon"
                          style={{ height: '42px' }}
                        />
                        <Typography
                          sx={{
                            color: 'white',
                            backgroundColor: '#380563',
                            padding: '2px 6px',
                            borderRadius: '28px',
                            fontSize: '10px',
                            position: 'relative',
                            marginTop: '-39px',
                            right: '11px'
                          }}
                        >
                          {countOfTeacherFilter}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>

                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    my={1.5}
                  >
                    <Grid display={'flex'} alignItems={'center'}>
                      <Checkbox
                        edge="start"
                        checked={
                          selectedLeft?.length === leftItems?.length &&
                          leftItems?.length > 0
                        }
                        onChange={handleSelectAllLeft}
                        tabIndex={-1}
                        disableRipple
                        color="primary"
                        disabled={leftItems?.length === 0}
                      />

                      <Typography>Select all</Typography>
                    </Grid>
                    <Button
                      variant={
                        selectedLeft?.length === 0 ? 'outlined' : 'contained'
                      }
                      onClick={moveToRight}
                    >
                      Add to Batch
                      <img
                        style={{
                          width: '16px',
                          height: '12px',
                          marginLeft: '8px'
                        }}
                        alt="BTC"
                        src={
                          selectedLeft?.length === 0
                            ? '/static/images/placeholders/logo/batchSwap.svg'
                            : '/static/images/placeholders/logo/batchSwap2.svg'
                        }
                      />
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      background: '#BFBFD8',
                      p: 2,
                      maxHeight: '1000px',
                      overflowY: 'auto',
                      mx: -2,
                      height: '950px'
                    }}
                  >
                    {leftItems?.length > 0 ? (
                      leftItems?.map((item) => (
                        <Card sx={{ mb: 1 }}>
                          <ListItem
                            key={item.userId}
                            button
                            onClick={() => toggleSelectionLeft(item)}
                          >
                            <Grid>
                              <ListItemIcon sx={{ minWidth: '35px' }}>
                                <Checkbox
                                  edge="start"
                                  checked={selectedLeft?.includes(item)}
                                  tabIndex={-1}
                                  disableRipple
                                  color="primary"
                                />
                              </ListItemIcon>
                            </Grid>
                            <Grid
                              item
                              container
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{ flex: 1 }}
                            >
                              <Grid item sx={{ color: 'black', flex: 1 }}>
                                <Box display={'flex'} alignItems={'center'}>
                                  <img
                                    style={{
                                      width: '33px',
                                      height: '33px',
                                      marginRight: '8px'
                                    }}
                                    onClick={handleBatchEdit}
                                    alt="BTC"
                                    src="/static/images/placeholders/logo/people.svg"
                                  />

                                  <Box>
                                    <Typography
                                      sx={{
                                        maxWidth: '170px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        fontWeight: 600,
                                        fontSize: '14px',
                                        lineHeight: '16px'
                                      }}
                                    >
                                      {item?.teacherData?.name || item?.name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        maxWidth: '170px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        fontWeight: 500,
                                        fontSize: '12px',
                                        lineHeight: '12px',
                                        color: '#39557E',
                                        mt: 0.5
                                      }}
                                    >
                                      {item?.teacherData?.email || item?.email}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setRightItems((prev) => [...prev, item]);
                                    setInitialRightSideComplete((prev) => [
                                      ...prev,
                                      item
                                    ]);
                                    setLeftItems((prev) =>
                                      prev.filter(
                                        (i) => i.userId !== item.userId
                                      )
                                    );
                                  }}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: '#380563',
                                    borderRadius: '25px',
                                    padding: '2px !important'
                                  }}
                                >
                                  <AddTwoToneIcon sx={{ color: 'white' }} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </Card>
                      ))
                    ) : (
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          height: '100%',
                          textAlign: 'center',
                          minHeight: '300px'
                        }}
                      >
                        <Grid item>
                          <img src={FileEmptyIcon} alt="Empty" />
                        </Grid>
                        <Grid item sx={{ mt: 2 }}>
                          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            No data available
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid
                      xs={12}
                      lg={5}
                      mb={1}
                      display="flex"
                      alignItems="center"
                    >
                      <img
                        style={{
                          width: '29px',
                          height: '29px',
                          marginRight: '8px'
                        }}
                        alt="BTC"
                        src="/static/images/placeholders/logo/3User.svg"
                      />
                      <Typography
                        sx={{
                          fontSize: { xs: '16px', md: '18px' },
                          fontWeight: 600,
                          lineHeight: '22px',
                          color: '#01011A'
                        }}
                      >
                        Batch
                      </Typography>
                      <Button sx={{ p: 0, display: 'contents' }}>
                        <img
                          style={{
                            width: '30px',
                            height: '30px',
                            marginLeft: '8px'
                          }}
                          onClick={handleBatchEdit}
                          alt="BTC"
                          src="/static/images/placeholders/logo/edit_icon.svg"
                        />
                      </Button>
                    </Grid>

                    <Grid item xs={12} lg={7} display={'flex'}>
                      <Grid item xs={8}>
                        <TextField
                          variant="outlined"
                          placeholder="Search..."
                          name="teacherNameFilter"
                          value={teacherNameFilter || ''}
                          onChange={handleBatchSearchChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                            sx: {
                              paddingRight: 0,
                              height: { xs: '35px', md: '43px' },
                              width: '100%'
                            }
                          }}
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                      <Grid item xs={2} ml={1}>
                        <Button
                          sx={{
                            padding: '0 !important',
                            background: 'none !important'
                          }}
                          onClick={handleBatchClick}
                        >
                          <img
                            src={filter}
                            alt="Filter icon"
                            style={{ height: '42px' }}
                          />
                          <Typography
                            sx={{
                              color: 'white',
                              backgroundColor: '#380563',
                              padding: '2px 6px',
                              borderRadius: '28px',
                              fontSize: '10px',
                              position: 'absolute',
                              marginTop: '-39px',
                              right: '4px'
                            }}
                          >
                            {countOfBatchTeacherFilter}
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <>
                          <Button
                            sx={{
                              padding: '0 !important',
                              background: 'none !important'
                            }}
                            onClick={handleMenuClick}
                          >
                            <img
                              src={action}
                              alt="action icon"
                              style={{ height: '42px' }}
                            />
                          </Button>
                          <Menu
                            anchorEl={anchorMenuEl}
                            open={openMenu}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={handleSelectAllRight}
                              sx={{ marginBottom: '8px', height: '48px' }}
                            >
                              <Typography
                                fontWeight={500}
                                fontSize={'14px'}
                                lineHeight={'14px'}
                                color={'#000000'}
                              >
                                Select All
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={handleUnselectAllRight}
                              sx={{ marginBottom: '8px', height: '48px' }}
                            >
                              <Typography
                                fontWeight={500}
                                fontSize={'14px'}
                                lineHeight={'14px'}
                                color={'#000000'}
                              >
                                Unselect All
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              sx={{ marginBottom: '8px', height: '48px' }}
                              onClick={moveToLeft}
                            >
                              <Typography
                                fontWeight={500}
                                fontSize={'14px'}
                                lineHeight={'14px'}
                                color={'#000000'}
                              >
                                Remove from Batch
                              </Typography>
                            </MenuItem>
                          </Menu>
                        </>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontSize: { xs: '16px', md: '18px' },
                          fontWeight: 600,
                          lineHeight: '22px',
                          marginBottom: '2px',
                          color: '#01011A'
                        }}
                      >
                        {batchListData?.name}
                      </Typography>

                      <Grid display="flex" alignItems="center" mt={1}>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#39557E',
                            fontWeight: 600
                          }}
                        >
                          {new Date(
                            batchListData?.createdOn
                          ).toLocaleDateString('en-GB')}
                        </Typography>
                        <img
                          style={{
                            width: '18px',
                            height: '18px',
                            marginRight: '8px',
                            marginLeft: '15px'
                          }}
                          alt="BTC"
                          src="/static/images/placeholders/logo/members.svg"
                        />
                        <Typography
                          sx={{
                            color: '#39557E',
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '14px'
                          }}
                        >
                          {`${displayItems?.length}`} members
                        </Typography>
                      </Grid>

                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: '#39557E',
                          fontWeight: 500,
                          lineHeight: '14.5px',
                          marginTop: 1,
                          height: '48px',
                          textAlign: 'justify',
                          marginBottom: '20px'
                        }}
                      >
                        {batchListData?.description}
                      </Typography>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      background: '#BFBFD8',
                      p: 2,
                      maxHeight: '900px',
                      height: '870px',
                      overflowY: 'auto',
                      mx: -2
                    }}
                  >
                    <Grid container spacing={2}>
                      {displayItems.length > 0 ? (
                        displayItems.map((item) => (
                          <Grid
                            item
                            md={6}
                            xl={6}
                            xs={12}
                            sx={{ pt: '18px !important', width: '100%' }}
                          >
                            <Card sx={{ mb: 0 }} key={item.userBatchId}>
                              <ListItem
                                button
                                onClick={() => toggleSelectionRight(item)}
                              >
                                <Checkbox
                                  edge="start"
                                  checked={selectedRight.includes(item)}
                                  tabIndex={-1}
                                  disableRipple
                                  color="primary"
                                />

                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{ flex: 1 }}
                                >
                                  <Box display={'flex'} alignItems={'center'}>
                                    <img
                                      style={{
                                        width: '33px',
                                        height: '33px',
                                        marginRight: '8px'
                                      }}
                                      onClick={handleBatchEdit}
                                      alt="BTC"
                                      src="/static/images/placeholders/logo/people.svg"
                                    />

                                    <Box>
                                      <Typography
                                        sx={{
                                          maxWidth: '200px',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          fontWeight: 600,
                                          fontSize: '14px',
                                          lineHeight: '16px',
                                          color: 'black'
                                        }}
                                      >
                                        {item?.teacherData?.name || item?.name}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          maxWidth: '180px',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          fontWeight: 500,
                                          fontSize: '12px',
                                          lineHeight: '12px',
                                          color: '#39557E',
                                          mt: 0.5
                                        }}
                                      >
                                        {item?.teacherData?.email ||
                                          item?.email}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Grid item>
                                    <IconButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveClick(item);
                                      }}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        background: 'red',
                                        borderRadius: '25px',
                                        padding: '2px !important'
                                      }}
                                    >
                                      <RemoveIcon sx={{ color: 'white' }} />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </ListItem>
                            </Card>
                          </Grid>
                        ))
                      ) : (
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            height: '100%',
                            textAlign: 'center',
                            minHeight: '300px'
                          }}
                        >
                          <Grid item>
                            <img src={FileEmptyIcon} alt="Empty" />
                          </Grid>
                          <Grid item sx={{ mt: 2 }}>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: 'bold' }}
                            >
                              No data available
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </CardContent>
                <Grid item xs={12} p={2} mt={-2}>
                  <FormActionButtons
                    handleCancel={handleCancel}
                    handleSave={handleSave}
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}

      {open && (
        <FilterPopOver
          subjectData={subjectData}
          gradeData={gradeData}
          businessData={businessData}
          selectedItems={selectedItems}
          handleDelete={handleDelete}
          handleAdd={handleAddItem}
          gradeId={gradeId}
          subjectId={subjectId}
          setSelectedGradeId={setSelectedGradeId}
          setSelectedSubjectId={setSelectedSubjectId}
          setFormData={setFormData}
          formData={formData}
          handleClose={handleClose}
          cityData={cityData}
          handleSave={handleFilterSave}
          setSelectedItems={setSelectedItems}
          open={open}
          handleCancel={handleCancel}
        />
      )}
      {openRight && (
        <BatchFilterPopOver
          subjectData={subjectData}
          openRight={openRight}
          gradeData={gradeData}
          businessData={businessData}
          selectedBatchItems={selectedBatchItems}
          handleDelete={handleDeleteBathcFiltered}
          handleAddBatchItem={handleAddBatchItem}
          batchGradeId={batchGradeId}
          bathcSubjectId={bathcSubjectId}
          setBatchGradeId={setBatchGradeId}
          setBathcSubjectId={setBathcSubjectId}
          bnatchSetFormData={bnatchSetFormData}
          handleSave={handleBatchFilterSave}
          batchFormData={batchFormData}
          handleClose={handleBatchClose}
          cityData={cityData}
          setSelectedBathcItems={setSelectedBathcItems}
        />
      )}
      {modalOpen && (
        <BatchModel
          open={modalOpen}
          handleCancel={handleModelCancel}
          handleSave={handleBatchUpdate}
          title={'Edit Batch'}
          subtitle={'Here you can edit Batch in field'}
          formData={modelFormData}
          handleInputChange={handleInputChange}
          handleBlur={handleBlur}
          touchedFields={touchedFields}
          inputProps={{ minLength: 1, maxLength: 100 }}
          inputLabelProps={{ shrink: true }}
          buttonText={'Update'}
          captionFieldLabel="Batch Name"
          captionFieldLabel1="Description"
        />
      )}
    </>
  );
};

export default TransferList;
