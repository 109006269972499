import { useEffect, useState } from 'react';
import { useNavigate, useRoutes, useLocation } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PanelNameProvider } from './components/PageHeader/PanelNameProvider';
import routes from './router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { getFromLocalStorage } from './common/user.info';
import { getLoginUser } from './pages/Login/service/login.services';
import { setUser, setPermissions } from './redux/slices/authSlice';
import SuspenseLoader from './components/SuspenseLoader';

const queryClient = new QueryClient();

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = useSelector((state: RootState) => state.auth.permissions);
  const userData = useSelector((state: RootState) => state.auth.user);
  const userType = userData?.userType;
  const token = getFromLocalStorage('auth_token');
  const [loading, setLoading] = useState(true);
  const toastId = 'subjectGradeToast';

  useEffect(() => {
    const fetchUserData = async () => {
      if (token && !userData) {
        try {
          const response = await getLoginUser();
          if (response?.data?.user[0]) {
            dispatch(setUser(response?.data?.user[0]));
            dispatch(
              setPermissions(response?.data?.user[0]?.permissions || [])
            );
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [dispatch, token, userData]);

  useEffect(() => {
    if (!loading) {
      const publicPaths = [
        '/login',
        '/email-verify',
        '/set-password',
        '/signin',
        '/signup',
        '/optverification',
        '/createNewPassword',
        '/auth/google'
      ];
      const privatePaths = ['/dashboard/', '/createNewPassword/'];

      const isLoggedIn = !!permissions.length;

      const currentPath = location.pathname;

      const isPublicPath = publicPaths.includes(currentPath);
      const isPrivatePath = privatePaths.some((path) =>
        currentPath.startsWith(path)
      );

      if (!isPublicPath && !isPrivatePath && !isLoggedIn) {
        navigate('/login');
      }
      if (
        userType === 'TEACHER' &&
        (!userData?.teacher?.subjectGrade ||
          userData?.teacher?.subjectGrade.length === 0)
      ) {
        navigate('/profile');
        if (!toast.isActive(toastId)) {
          toast.info('Please add at least one subject grade.', { toastId });
        }
      }
      if (userType === 'TEACHER' && !userData?.teacher) {
        navigate('/teacherRegister');
        if (!toast.isActive(toastId)) {
          toast.info('Please add at least one subject grade.', { toastId });
        }
      }
    }
  }, [permissions, location.pathname, navigate, loading, userType, userData]);

  if (loading) {
    return <SuspenseLoader />;
  }

  const content = useRoutes(routes(permissions, userType));

  return (
    <ThemeProvider>
      <PanelNameProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            {content}
            <ToastContainer />
          </QueryClientProvider>
        </LocalizationProvider>
      </PanelNameProvider>
    </ThemeProvider>
  );
};

export default App;
