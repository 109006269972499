import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import SelectAllTransferList from './list';
import { usePanelName } from 'src/components/PageHeader/PanelNameProvider';

const panelName = 'Users';

const BatchForm: React.FC = () => {
  const { setPanelName } = usePanelName();

  useEffect(() => {
    setPanelName(panelName);
  }, [setPanelName]);

  return (
    <>
      <SelectAllTransferList />
    </>
  );
};

export default BatchForm;
