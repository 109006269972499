import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface MainFormState {
  name: string;
  instruction: string;
  duration: string;
  start: Date | null;
  end: Date | null;
}

const initialMainFormState: MainFormState = {
  name: '',
  instruction: '',
  duration: '',
  start: null,
  end: null
};

const mainFormSlice = createSlice({
  name: 'mainForm',
  initialState: initialMainFormState,
  reducers: {
    setMainFormData: (state, action: PayloadAction<any>) => {
      state.name = action.payload.name;
      state.instruction = action.payload.instruction;
      state.duration = action.payload.duration;
      state.start = action.payload.start;
      state.end = action.payload.end;
    },
    clearMainFormData: (state) => {
      state.name = '';
      state.instruction = '';
      state.duration = '';
      state.start = null;
      state.end = null;
    }
  }
});

export const { setMainFormData, clearMainFormData } = mainFormSlice.actions;
export default mainFormSlice.reducer;
