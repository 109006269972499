import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import filtersReducer from './slices/filterSlice';
import dynamicAssessmentReducer from './slices/dynamicAssessment';
import batchReducer from './slices/batchdynamicAssessment';
import mainFormReducer from './slices/mainFormDynamicAssessment';
import subjctGradeReducer from './slices/subjectGradeDynamicAssessment';
import subjctGradeCategoryReducer from './slices/subjectGradeCategoryDynamicAssessment';

const store = configureStore({
  reducer: {
    auth: authReducer,
    filters: filtersReducer,
    dynamicAssessment: dynamicAssessmentReducer,
    batch: batchReducer,
    mainForm: mainFormReducer,
    subjectGrade: subjctGradeReducer,
    subjectGradeCategory: subjctGradeCategoryReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
