import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import {
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useParams } from 'react-router';
import CustomTable from 'src/components/Table/customTable';
import SearchIcon from '@mui/icons-material/Search';
import { getBatchById } from '../service/batch.services';
import { usePanelName } from 'src/components/PageHeader/PanelNameProvider';
import TeacherViewModel from 'src/shared/teacherViewModel';
import SuspenseLoader from 'src/components/SuspenseLoader';
import BackButton from 'src/shared/backButton';
import PanelWithCreateButton from 'src/components/PageHeader';

const panelName = 'Batch Detail';

const BatchView = () => {
  let { batchId } = useParams();
  const theme = useTheme();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { setPanelName } = usePanelName();
  const [teacherName, setTeacherName] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTeacherId, setSelectedTeacherId] = useState<any>(null);

  useEffect(() => {
    setPanelName(panelName);
  }, [setPanelName]);

  const { data: responseData, isLoading } = useQuery<any>(
    ['batch', batchId, currentPage, limit, teacherName],
    () =>
      getBatchById(parseInt(batchId), {
        limit: limit,
        offset: (currentPage - 1) * limit,
        name: teacherName ? `%${teacherName}%` : ''
      }),
    {
      enabled: !!batchId,
      keepPreviousData: true
    }
  );

  const batchData = responseData?.data?.batch[0];

  const data = responseData?.data?.batch?.map((item) => item.teacherData);

  const dataTableColumns = [
    { title: 'Name', dataIndex: 'name' },
    { title: 'Email', dataIndex: 'email' }
  ];

  // const onSearchChange = (name: string, value: string) => {
  //   if (name === 'teacherName') {
  //     setTeacherName(value);
  //   }
  // };

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target;
  //   onSearchChange(name, value);
  // };
  // const handleSearchChange = (event) => {
  //   const { value } = event.target;
  //   const trimmedSearchName = value.trim() || '';
  //   if (trimmedSearchName) {
  //     setTeacherName(trimmedSearchName);
  //     setCurrentPage(1);
  //     setLimit(10);
  //   } else {
  //     setTeacherName(null);
  //   }
  // };

  const handleSearchChange = (teacherName) => {
    const trimmedSearchName = teacherName?.trim() || '';
    if (trimmedSearchName) {
      setTeacherName(trimmedSearchName);
      setCurrentPage(1);
      setLimit(10);
    } else {
      setTeacherName(null);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onDetailViewTeacher = (teacherId: any) => {
    setSelectedTeacherId(teacherId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const actions = [
    {
      label: 'View',
      onClick: onDetailViewTeacher,
      icon: (
        <Tooltip key="view" title="View Attended Assessment" arrow>
          <IconButton
            sx={{
              '&:hover': { background: theme.colors.success.lighter },
              color: theme.palette.success.main
            }}
            color="inherit"
            size="medium"
          >
            <img
              style={{ width: '24px', height: '24px' }}
              alt="BTC"
              src="/static/images/placeholders/logo/preview_eye.svg"
            />
          </IconButton>
        </Tooltip>
      )
    }
  ];

  return (
    <>
      <BackButton title="Back" />
      <div style={{ padding: '0px 20px 20px 20px' }}>
        {isLoading && <SuspenseLoader />}
        <Card
          variant="outlined"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '300px'
          }}
        >
          {/* <Grid p={2} pb={1}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={9}>
                <Typography
                  sx={{
                    fontSize: { xs: '16px', md: '18px' },
                    fontWeight: 600,
                    lineHeight: '22px',
                    marginBottom: '2px',
                    color: '#01011A'
                  }}
                >
                  {batchData?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    color: '#39557E',
                    fontWeight: 500,
                    lineHeight: '14.5px',
                    marginTop: 0
                  }}
                >
                  {batchData?.description}
                </Typography>
              </Grid>

              <>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    placeholder="Search..."
                    name="teacherName"
                    value={teacherName}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      sx: {
                        paddingRight: 0,
                        height: { xs: '35px', md: '43px' },
                        width: '100%'
                      }
                    }}
                    fullWidth
                    inputProps={{ maxLength: 320 }}
                  />
                </Grid>
              </>
            </Grid>
          </Grid> */}
          <Grid p={2} pb={1}>
            <PanelWithCreateButton
              panelName={batchData?.name}
              onSearchChange={handleSearchChange}
              data={batchData?.description}
            />
          </Grid>
          <Grid p={2}>
            <CustomTable
              columns={dataTableColumns}
              data={data || []}
              actions={actions}
              onPageChange={handlePageChange}
              idKey="teacherId"
              currentPage={currentPage}
              limit={limit}
              setLimit={setLimit}
              pageSize={responseData?.pagination?.offset}
              totalItems={responseData?.pagination?.total || 0}
            />
          </Grid>
        </Card>
        <TeacherViewModel
          open={modalOpen}
          selectedTeacherId={selectedTeacherId}
          onClose={handleCloseModal}
        />
      </div>
    </>
  );
};

export default BatchView;
