import { del, get, post, put } from 'src/services/axios.service';
import { IBusiness } from './business.interface';

export const getBusinessList = async (
  filterData: { limit?: number; offset?: number; name?: string } = {}
): Promise<IBusiness[]> => {
  try {
    const { limit, offset, name } = filterData;
    const businessList = await get<IBusiness[]>('/business', {
      params: { limit, offset, name }
    });
    return businessList;
  } catch (error) {
    throw error;
  }
};

export const createBusiness = async (
  businessData: Partial<IBusiness>
): Promise<IBusiness> => {
  try {
    const createdBusiness = await post<IBusiness>('/business', businessData);
    return createdBusiness;
  } catch (error) {
    throw error;
  }
};

export const getBusinessById = async (
  businessId: number
): Promise<IBusiness | null> => {
  try {
    const business = await get<IBusiness>(`/business/${businessId}`);
    return business;
  } catch (error) {
    throw error;
  }
};

export const updateBusiness = async (
  businessId: number,
  businessData: IBusiness
): Promise<IBusiness | null> => {
  try {
    const updatedBusiness = await put<IBusiness>(
      `/business/${businessId}`,
      businessData
    );
    return updatedBusiness;
  } catch (error) {
    throw error;
  }
};

export const deleteBusiness = async (businessId: number): Promise<boolean> => {
  try {
    const deleteResponse = await del<any>(`/business/${businessId}`);
    return (
      deleteResponse?.status === true && deleteResponse?.data?.deleted === true
    );
  } catch (error) {
    throw error;
  }
};
