import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const FullScreenWrapper: React.FC<{ children: React.ReactNode; enterFullScreen: boolean }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [backButtonClickCount, setBackButtonClickCount] = useState(0);

  useEffect(() => {
    const handleBackButtonEvent = (event: PopStateEvent) => {
      event.preventDefault();
      setBackButtonClickCount((prevCount) => prevCount + 1);
      window.history.pushState(null, '', window.location.href + `?${Date.now()}`);
    };

    const preventReload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    const preventKeyStrokes = (event: KeyboardEvent) => {
      const forbiddenKeys = [
        'F12', 'F11', 'F5', 'Control+Shift+I', 'Control+Shift+C', 'Control+Shift+J', 'Control+U'
      ];
      if (forbiddenKeys.includes(event.key) ||
        (event.ctrlKey && (['i', 'I', 'c', 'C', 'j', 'J', 'u', 'U'].includes(event.key)))
      ) {
        event.preventDefault();
      }
    };

    const disableContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };

    const disableCopyCutPaste = (event: ClipboardEvent) => {
      event.preventDefault();
    };

    const disableTextSelection = (event: Event) => {
      event.preventDefault();
    };

    window.history.pushState(null, '', window.location.href); // Initial call to push state
    window.addEventListener('popstate', handleBackButtonEvent);
    window.addEventListener('beforeunload', preventReload);
    document.addEventListener('keydown', preventKeyStrokes);
    document.addEventListener('contextmenu', disableContextMenu);
    document.addEventListener('copy', disableCopyCutPaste);
    document.addEventListener('cut', disableCopyCutPaste);
    document.addEventListener('paste', disableCopyCutPaste);
    document.addEventListener('selectstart', disableTextSelection);

    return () => {
      window.removeEventListener('popstate', handleBackButtonEvent);
      window.removeEventListener('beforeunload', preventReload);
      document.removeEventListener('keydown', preventKeyStrokes);
      document.removeEventListener('contextmenu', disableContextMenu);
      document.removeEventListener('copy', disableCopyCutPaste);
      document.removeEventListener('cut', disableCopyCutPaste);
      document.removeEventListener('paste', disableCopyCutPaste);
      document.removeEventListener('selectstart', disableTextSelection);
    };
  }, [location, navigate]);

  return (
    <>
      {children}
    </>
  );
};

export default FullScreenWrapper;